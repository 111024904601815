.work_container {
  .title {
    font-weight: 700;
    font-size: 50px;
    line-height: 138.34%;
    text-align: center;
    color: #000000;
    width: 80%;
    margin: 0 auto 50px;
  }
  .apps_tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 23px;
    margin: 30px 0 20px;
    button {
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 14px 16px;
      background-color: #ffffff;
      transition: 0.3s all;
      border: 0;
      font-size: 18px;
      font-weight: 600;
      line-height: 24.9px;
      color: #0d0f27;
      height: 91px;
      width: 150px;
      svg {
        fill: #5d5a5b;
        margin-bottom: 5px;
        transition: 0.3s all;
      }
      &.all {
        &:hover,
        &.active {
          background-color: #d7f5c4;
        }
      }
      &.web {
        &:hover,
        &.active {
          background-color: #c8e5f9;
        }
      }
      &.mobile {
        &:hover,
        &.active {
          background-color: #fbf0cb;
        }
      }
    }
  }
  .filter_tabs {
    margin-bottom: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .app_name {
      font-size: 20px;
      font-weight: 600;
      line-height: 35px;
      padding: 10px 25px;
      border-bottom: 1px solid #5d5a5b;
      position: relative;
      overflow: hidden;
      transition: 0.3s all;
      margin-top: 10px;
      cursor: pointer;
      user-select: none;
      &::after {
        content: "";
        height: 4px;
        width: 100%;
        background-color: #1886cd;
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: 0;
        transform: translateY(4px);
        transition: 0.3s;
      }
      &.active,
      &:hover {
        color: #1886cd;
        border-bottom: 1px solid #1886cd;
        &::after {
          opacity: 1;
          transform: translateY(0px);
        }
      }
    }
  }
  a {
    text-decoration: none;
    .work_box {
      background: #ffffff;
      border-radius: 30px;
      padding: 0px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;
      position: relative;
      overflow: hidden;
      height: calc(100% - 20px);
      .wok__image__wrapper {
        width: 100%;
        position: relative;
        z-index: 1;
        background-color: #1886cd;
        margin-bottom: 50px;
        height: 220px;
        img {
          position: absolute;
          top: 50px;
          left: 50px;
          border-radius: 15px;
          width: calc(100% - 100px);
          height: auto;
          object-fit: cover;
        }
      }
      .work_content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        padding: 30px 50px 50px;
        height: calc(100% - 270px);
        > div:first-child {
          height: 100%;
          display: flex;
          flex-direction: column;
        }
        .box_title {
          font-weight: 700;
          font-size: 26px;
          line-height: 35px;
          letter-spacing: 0.5px;
          color: #1886cd;
          margin-bottom: 26px;
          min-height: 70px;
        }
        .box_content {
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: #0d0f27;
          -webkit-line-clamp: 6;
          margin-bottom: 20px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
        .box_action {
          margin-top: 40px;
          font-weight: 600;
          font-size: 22px;
          line-height: 30px;
          text-align: center;
          letter-spacing: -0.8px;
          color: #0d0f27;
          background-color: transparent;
          border: 0;
          outline: none;
          img {
            margin-left: 15px;
            width: 28px;
            height: 28px;
          }
        }
        .box_badges {
          margin-top: auto;
          display: flex;
          align-items: center;
          gap: 8px;
          .badge_blue {
            padding: 3px 16px;
            line-height: 22px;
            border-radius: 18px;
            background-color: #f0f9ff;
            color: #026aa2;
          }
          .badge_purple {
            padding: 3px 16px;
            line-height: 22px;
            border-radius: 18px;
            background-color: #eef4ff;
            color: #3538cd;
          }
          .badge_red {
            padding: 3px 16px;
            line-height: 22px;
            border-radius: 18px;
            background-color: #fff6ed;
            color: #c4320a;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .work_container {
    a {
      .work_box {
        .wok__image__wrapper {
          height: 220px;
          img {
            top: 35px;
            left: 34px;
            width: calc(100% - 70px);
          }
        }
        .work_content {
          padding: 0 30px 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .work_container {
    a {
      .work_box {
        .wok__image__wrapper {
          height: 180px;
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .work_container {
    a {
      .work_box {
        .wok__image__wrapper {
          height: 180px;
          img {
            top: 35px;
            left: 34px;
            width: calc(100% - 70px);
          }
        }
        .work_content {
          padding: 0 30px 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .work_container {
    a {
      .work_box {
        .wok__image__wrapper {
          height: 250px;
          img {
            position: absolute;
            top: 35px;
            left: 34px;
            border-radius: 15px;
            width: calc(100% - 70px);
            height: auto;
            object-fit: cover;
          }
        }
        .work_content {
          padding: 0 30px 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .work_container {
    // padding-bottom: 350px;
    .title {
      font-size: 40px;
      width: 80%;
    }
    a {
      .work_box {
        .wok__image__wrapper {
          height: 170px;
          img {
            position: absolute;
            top: 25px;
            left: 25px;
            border-radius: 15px;
            width: calc(100% - 50px);
            height: auto;
            object-fit: cover;
          }
        }
        .work_content {
          padding: 0 30px 30px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .work_container {
    padding-bottom: 30px;
    .title {
      font-size: 34px;
      width: 100%;
      line-height: 40px;
      margin-bottom: 40px;
    }
    a {
      .work_box {
        .wok__image__wrapper {
          height: 160px;
          img {
            position: absolute;
            top: 25px;
            left: 25px;
            border-radius: 15px;
            width: calc(100% - 50px);
            height: auto;
            object-fit: cover;
          }
          .work_content {
            height: calc(100% - 210px);
            padding: 0px 30px;
          }
        }
      }
    }
  }
}
