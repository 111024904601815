@font-face {
  font-family: 'sf-pro';
  src: url('./assets/fonts/SF-Pro/SF-Pro-Display-Thin.otf');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'sf-pro';
  src: url('./assets/fonts/SF-Pro/SF-Pro-Display-ThinItalic.otf');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'sf-pro';
  src: url('./assets/fonts/SF-Pro/SF-Pro-Display-Ultralight.otf');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'sf-pro';
  src: url('./assets/fonts/SF-Pro/SF-Pro-Display-UltralightItalic.otf');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'sf-pro';
  src: url('./assets/fonts/SF-Pro/SF-Pro-Display-Light.otf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'sf-pro';
  src: url('./assets/fonts/SF-Pro/SF-Pro-Display-LightItalic.otf');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'sf-pro';
  src: url('./assets/fonts/SF-Pro/SF-Pro-Display-Regular.otf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'sf-pro';
  src: url('./assets/fonts/SF-Pro/SF-Pro-Display-RegularItalic.otf');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'sf-pro';
  src: url('./assets/fonts/SF-Pro/SF-Pro-Display-Medium.otf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'sf-pro';
  src: url('./assets/fonts/SF-Pro/SF-Pro-Display-MediumItalic.otf');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'sf-pro';
  src: url('./assets/fonts/SF-Pro/SF-Pro-Display-Semibold.otf');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'sf-pro';
  src: url('./assets/fonts/SF-Pro/SF-Pro-Display-SemiboldItalic.otf');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'sf-pro';
  src: url('./assets/fonts/SF-Pro/SF-Pro-Display-Bold.otf');
  font-weight: 700;
  font-style: normal;
}
@font-face {
 font-family: 'sf-pro';
 src: url('./assets/fonts/SF-Pro/SF-Pro-Display-BoldItalic.otf');
 font-weight: 700;
 font-style: italic;
}
@font-face {
  font-family: 'sf-pro';
  src: url('./assets/fonts/SF-Pro/SF-Pro-Display-Heavy.otf');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'sf-pro';
  src: url('./assets/fonts/SF-Pro/SF-Pro-Display-HeavyItalic.otf');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'sf-pro';
  src: url('./assets/fonts/SF-Pro/SF-Pro-Display-Black.otf');
  font-weight: 900;
  font-style: normal;
}
@font-face {
 font-family: 'sf-pro';
 src: url('./assets/fonts/SF-Pro/SF-Pro-Display-BlackItalic.otf');
 font-weight: 900;
 font-style: italic;
}






@font-face {
  font-family: 'roboto';
  src: url('./assets/fonts/Roboto/Roboto-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}



@font-face {
  font-family: 'helvetica';
  src: url('./assets/fonts/Helvetica/HelveticaNeue-BoldCond.otf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'helvetica';
  src: url('./assets/fonts/Helvetica/HelveticaNeue-BoldCondObl.otf');
  font-weight: bold;
  font-style: italic;
}


*{
   box-sizing: border-box;
   padding: 0;
   margin: 0
}
body{
   font-family: sf-pro;
   background: #F4F4F4;
}
@media screen and (min-width: 1900px) {
   .container {
       width: 100%;
       max-width: 1480px;
       width: 1480px;
       margin: 0 auto;
       padding: 0 20px;
     }
}

