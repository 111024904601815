// Case Studie Card Start
.case_card{
    background: #FFFFFF;
    border: 0px solid rgba(93, 90, 91, 0.2);
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 25px;
    height: calc(100% - 30px);
    a{
    text-decoration: none;
    .case_img{
        background: #F9F8F8;
        border-radius: 20px 20px 0px 0px;
        width: 100%;
        padding: 40px 42px 0;
        &.c_img1{background-color:#ddf7c8;}
        &.c_img2{background-color:#f4f2c9;}
        &.c_img3{background-color:#dadff4;}
        &.c_img4{background-color:#f8e0ef;}
        &.c_img5{background-color:#d8e2dd;}
        &.c_img6{background-color:#e9d9cc;}
        &.c_img7{background-color:#C5D1FF;}
        &.c_img8{background-color:#D5C8FC;}
        img{
            min-width: 330px;
            width: 100%;
        }
        .title{
            text-align: center;
            font-size: 30px;
            font-weight: 700;
            line-height: 40px;
            letter-spacing: 0px;
            margin-bottom: 0px;
            color: #000;
        }
    }
}
    .case_title{
        padding: 30px;
        .mini_title{
            font-size: 22px;
            font-weight: 700;
            line-height: 29px;
            letter-spacing: 0px;
            margin-bottom: 15px;
            text-align: center;
            color: #000000;
        }
        .platform{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            span{
                display: block;
                border-radius: 18.5px;
                padding:5px 15px;
                margin: 0 6px;
                color: #0D0F27;
                background: rgba(0, 0, 0, 0.1);
                font-size: 16px;
                font-weight: 500;
                line-height: 19px;
                letter-spacing: 0px;
                margin-top: 10px;
                &.web{
                    color: #1886CD;
                    background: rgba(24, 134, 205, 0.1);
                }
                &.ios{
                    color: #0D0F27;
                    background: rgba(0, 0, 0, 0.1);
                }
                &.app{
                    color: #79C257;
                    background: rgba(121, 194, 87, 0.2);
                }
            }
        }
    }
}

// Case Studie Card End

// Case Studies Wrapper Start

.cc_wrapper{
    padding: 0px 0;
    .case_page_title{
        font-size: 50px;
        font-weight: 700;
        line-height: 69px;
        letter-spacing: 0px;
        width: 60%;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 84px;
    }
    // background-color: #fff;
}

// Case Studies Wrapper End


/* Mobile Devices */
@media screen and (max-width: 400px) {
    a{
        .case_card{
            .case_img{
                img{
                    min-width: 100%;
                }
                .title{
                    margin-bottom: 20px;
                }
            }
            .case_title{
                padding: 10px 10px 20px;
                .mini_title{
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 24px;
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .cc_wrapper{
        .case_page_title{
            width: 100%;
            font-size: 30px;
            line-height: 36px;
            margin-bottom: 25px;
        }
        padding: 60px 0;
        .case_card{
            width: 100%;
            min-width: 100%;
            .case_img{
                padding: 20px 20px 0px 20px;
            }
        }
    }
}

/* Tablet Devices */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .cc_wrapper{
        padding: 60px 0;
        .case_img{
            padding: 20px;
        }
        .case_card{
            .case_img{
                img{
                    min-width: 220px;
                }
            }
        }
    }
}

/* Laptop Devices */
@media screen and (min-width: 1024px) and (max-width: 1365px) {
    .cc_wrapper{
        .case_card{
            .case_img{
                padding: 20px 20px 0px 20px;
                img{
                    min-width: 100%;
                }
            }
        }
    }
}

/* Desktop Devices */
@media screen and (min-width: 1366px) and (max-width: 1919px) {}

/* TV Devices */
@media screen and (min-width: 1920px) {}