.industries_content{
    background: #F5F5F5;
    padding: 80px 0 140px 0;
    .owl-dots{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        margin-bottom: 10px;
        .owl-dot{
            background-color: #fff;
            height: 16px;
            width: 16px;
            margin: 0 5px;
            border-radius: 20px;
            position: relative;
            z-index: 1;
            &::after{
                content: "";
                height: 20px;
                width: 20px;
                background-color: #fff;
                border: 1px solid #1886cd;
                position: absolute;
                top: -2px;
                left: -2px;
                border-radius: 50%;
                z-index: 0;
                opacity: 0;
                transition: 0.3s;
            }
            &::before{
                content: "";
                height: 14px;
                width: 14px;
                background-color: #1886cd;
                position: absolute;
                top: 1px;
                left: 1px;
                border-radius: 50%;
                z-index: 1;
                opacity: 0;
                transition: 0.3s;
            }
            &.active{
                background: #fff;
                &::after, &::before{
                    opacity: 1;
                }
            }
        }
    }
    .title{
        font-weight: 700;
        font-size: 50px;
        line-height: 138.34%;
        text-align: center;
        color: #000000;
        width: 75%;
        margin: 0 auto 60px auto;

    }
    .about_image{
        max-width: 877px;
        max-height: 351px;
        width: 100%;
        margin: 0 auto;
        border-radius: 10px;
        position: relative;
        &::after{
            content: '';
            height: 90%;
            width: 90%;
            position: absolute;
            bottom: -12px;
            right: 0px;
            z-index: 0;
            background: #1886CD;
            border-radius: 10px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: relative;
            z-index: 1;
            border-radius: 10px;
        }
    }
}

// We Serve Section Start

.disc_text{
    width: 70%;
    margin: 0 auto; 
}

.we_serve{
    // background: #FFFFFF;
    padding: 150px 0 158px 0;
    .title{
        font-style: normal;
        font-weight: 700;
        font-size: 56px;
        line-height: 67px;
        color: #1886CD;
    }
    .desc{
        font-weight: 800;
        font-size: 30px;
        line-height: 36px;
        text-transform: capitalize;
        color: #0D0F27;
        margin-bottom: 100px;
        margin-top: 10px;
    }
    .serve_box{
        padding: 58px 40px;
        background: #fef6cd;
        border-radius: 18px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 25px;
        &.box1{
            background-color: #fef6cd;
        }
        &.box2{
            background-color: #fedeef;
        }
        &.box3{
            background-color: #dedffe;
        }
        &.box4{
            background-color: #e0fecd;
        }
        &.box5{
            background-color: #defcfe;
        }
        &.box6{
            background-color: #eedefe;
        }
        &.box7{
            background-color: #fecdcd;
        }
        &.box8{
            background-color: #fee9de;
        }
        &.box9{
            background-color: #e0f1f5;
        }
        &.box10{
            background-color: #fff1cc;
        }
        &.box11{
            background-color: #ededed;
        }
        .icon{
            width: 103px;
            margin-right: 40px;
            img{
                width: 100%;
            }
        }
        .text{
            font-weight: 700;
            font-size: 30px;
            line-height: 48px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: #0D0F27;
            margin-bottom: 15px;
            position: relative;
            z-index: 1;
            &::before{
                content: '';
                height: 1px;
                width: 132px;
                position: absolute;
                bottom: -15px;
                left: 0;
                background-color: #fff;
                z-index: 2;
            }
        }
    }
}

// We Serve Section End

/* Mobile Devices */
@media screen and (max-width: 767px) {
    .industries_content{
        padding: 60px 0 80px 0;
        .title{
            font-size: 42px;
            margin-bottom: 30px;
            line-height: 52px;
            margin-top: 0px;
            width: 100%;
        }
        .about_image{
            &::after{
                width: 95%;
                bottom: -6px;
                right: 5px;
            }
        }
    }
    .we_serve{
        padding: 60px 0;
        .title{
            font-size: 46px;
            margin-bottom: 0px;
            line-height: 46px;
            margin-top: 0px;
        }
        .desc{
            font-size: 18px;
            line-height: 24px;
            width: 100%;
            margin-top: 15px;
            margin-bottom: 30px;
        }
        .serve_box{
            padding: 25px 22px;
            .icon{
                margin-right: 30px;
            }
            .text{
                font-size: 25px;
                line-height: 34px;
            }
        }
    }
}

/* Tablet Devices */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .industries_content{
        padding: 40px 0 60px 0;
        .title{
            width: 100%;
        }
    }
    .we_serve{
        padding: 60px 0;
        .title{
            font-size: 46px;
            margin-bottom: 0px;
            line-height: 46px;
            margin-top: 0px;
        }
        .desc{
            font-size: 18px;
            line-height: 24px;
            width: 100%;
            margin-top: 15px;
            margin-bottom: 30px;
        }
        .serve_box{
            padding: 25px 22px;
            .icon{
                margin-right: 30px;
            }
            .text{
                font-size: 25px;
                line-height: 34px;
            }
        }
    }
}

/* Laptop Devices */
@media screen and (min-width: 1024px) and (max-width: 1365px) {
    .industries_content{
        padding: 40px 0 60px 0;
        .title{
            width: 100%;
        }
    }
    .we_serve{
        padding: 60px 0;
        .title{
            font-size: 46px;
            margin-bottom: 0px;
            line-height: 46px;
            margin-top: 0px;
        }
        .desc{
            font-size: 18px;
            line-height: 24px;
            width: 70%;
            margin-top: 15px;
            margin-bottom: 30px;
        }
        .serve_box{
            padding: 25px 22px;
            .icon{
                margin-right: 30px;
            }
            .text{
                font-size: 25px;
                line-height: 34px;
            }
        }
    }
}

/* Desktop Devices */
@media screen and (min-width: 1366px) and (max-width: 1919px) {}

/* TV Devices */
@media screen and (min-width: 1920px) {}