.heading_container {
  padding: 230px 0 80px 0;
  background-image: url(./../../assets/images/dots1.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .heading_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .page_name {
      font-weight: 700;
      font-size: 15.3493px;
      line-height: 20px;
      text-align: center;
      text-transform: uppercase;
      color: #1886cd;
      position: relative;
      &::after, &::before{
        content: '';
        left: -16px;
        top: 5px;
        width: 8px;
        height: 8px;
        background-color: #1886CD;
        position: absolute;
        z-index: 1;
        border-radius: 5PX;
        }
        &::before{
            left: unset;
            right: -16px;
        }
    }
    .page_title {
      font-weight: 800;
      font-size: 50px;
      line-height: 60px;
      text-align: center;
      color: #0d0f27;
      padding: 15px 0 20px;
    }
    .page_links {
      font-weight: 700;
      font-size: 15.3493px;
      line-height: 20px;
      text-align: center;
      text-transform: uppercase;
      color: #5d5a5b;
      a {
        text-decoration: none;
        color: #1886cd;
        padding: 0 5px;
      }
      span{
            padding: 0 5px;
      }
    }
  }
}


/* Mobile Devices */
@media screen and (max-width: 767px) {
  .heading_container {
    padding: 130px 0 60px 0;
    .page_title{
      font-size: 40px;
      line-height: 40px;
    }
  }
}

/* Tablet Devices */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .heading_container {
    padding: 130px 0 60px 0;
  }
}

/* Laptop Devices */
@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .heading_container{
    padding: 130px 0 70px 0;
  }
}

/* Desktop Devices */
@media screen and (min-width: 1366px) and (max-width: 1919px) {}

/* TV Devices */
@media screen and (min-width: 1920px) {}