.about_content {
  background-color: #fff;
  padding: 105px 0 170px 0;
  .owl-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: 10px;
    .owl-dot {
      background-color: #fff;
      height: 16px;
      width: 16px;
      margin: 0 5px;
      border-radius: 20px;
      position: relative;
      z-index: 1;
      &::after {
        content: "";
        height: 20px;
        width: 20px;
        background-color: #fff;
        border: 1px solid #1886cd;
        position: absolute;
        top: -2px;
        left: -2px;
        border-radius: 50%;
        z-index: 0;
        opacity: 0;
        transition: 0.3s;
      }
      &::before {
        content: "";
        height: 14px;
        width: 14px;
        background-color: #1886cd;
        position: absolute;
        top: 1px;
        left: 1px;
        border-radius: 50%;
        z-index: 1;
        opacity: 0;
        transition: 0.3s;
      }
      &.active {
        background: #fff;
        &::after,
        &::before {
          opacity: 1;
        }
      }
    }
  }
  .title {
    font-weight: 700;
    font-size: 50px;
    line-height: 138.34%;
    text-align: center;
    color: #000000;
    width: 75%;
    margin: 0 auto 22px auto;
    span {
      color: #1886cd;
    }
  }
  .desc {
    font-weight: 400;
    font-size: 20px;
    line-height: 138.34%;
    text-align: center;
    color: #000000;
    width: 65%;
    margin: 0 auto;
    margin-bottom: 60px;
  }
  .about_image {
    max-width: 877px;
    max-height: 351px;
    width: 100%;
    margin: 0 auto;
    border-radius: 10px;
    position: relative;
    &::after {
      content: "";
      height: 90%;
      width: 90%;
      position: absolute;
      bottom: -12px;
      right: -12px;
      z-index: 0;
      background: #1886cd;
      border-radius: 10px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      z-index: 1;
      border-radius: 10px;
    }
  }
  .word_ceo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 156px;
    height: 645px;
    .thumbnail {
      width: 40%;
      height: 100%;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      // .play{}
    }
    .content {
      width: 60%;
      height: 100%;
      background: #0d0f27;
      padding: 80px 60px 80px 128px;
      .mini_title {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #1886cd;
        margin-bottom: 12px;
        position: relative;
        &::after {
          content: "";
          height: 167px;
          width: 235px;
          left: -10px;
          top: -18px;
          position: absolute;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          position: absolute;
          background-image: url("./../../assets/images/quote.svg");
        }
      }
      .ceo_title {
        font-weight: 800;
        font-size: 56px;
        line-height: 67px;
        text-transform: capitalize;
        color: #ffffff;
      }
      .ceo_desc {
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #d7d7d7;
        padding: 60px 0 42px 0;
        font-style: italic;
        letter-spacing: 1px;
      }
      .name {
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        color: #ffffff;
        margin-bottom: 6px;
      }
      .position {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #1886cd;
      }
    }
  }
}

// Team Section Start

.team {
  padding: 125px 0;
  background-color: #0d0f27;
  .title {
    font-weight: 700;
    font-size: 56px;
    line-height: 67px;
    text-align: center;
    text-transform: capitalize;
    color: #1886cd;
  }
  .desc {
    font-weight: 800;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #ffffff;
    margin-top: 10px;
  }
  .team_wrapper {
    margin-top: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    .team_box {
      border-radius: 16px 16px 20px 20px;
      border: 1px solid #fff;
      margin-bottom: 25px;
      position: relative;
      width: calc(20% - 25px);
      height: 326px;
      margin-right: 25px;
      &:nth-child(5n) {
        margin-right: 0;
      }
      &::after {
        content: "";
        width: calc(100% + 2px);
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 43.34%,
          #151627 83.17%
        );
        mix-blend-mode: luminosity;
        border-radius: 16px;
        position: absolute;
        bottom: -1px;
        left: -1px;
      }
      .team_img {
        transition: 1s linear;
        height: 100%;
        width: 100%;
        border-radius: 16px;
        overflow: hidden;
        mix-blend-mode: luminosity;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: top;
        }
      }
      .team_detail {
        position: absolute;
        bottom: 0;
        padding: 20px;
        z-index: 1;
        .name {
          font-weight: 800;
          font-size: 23.7931px;
          line-height: 138.34%;
          color: #ffffff;
        }
        .position {
          font-weight: 500;
          font-size: 17.4483px;
          line-height: 138.34%;
          color: #1886cd;
        }
      }
      transition: 0.6s linear;
    }
    .team_box:hover {
      // mix-blend-mode: difference;
      transform: scale(1.1);
      mix-blend-mode: none;
      border: 1px solid black;
    }
  }
}

// Team Section End

// Vision Mission Start
.vision_mission{
  padding: 100px 0 30px;
  .common_box{
    border-radius: 20px;
    padding: 30px;
    height: calc(100% - 28px);
    margin-bottom: 28px;
    &.vision{
      background-color: #DCD9FF;
      .image{
        height: 100%;
      }
    }
    &.mission{
      background-color: #F9F1C8;
      .image{
        height: 288px;
        margin-top: 9px;
      }
    }
    &.values{
      background-color: #DAE4F9;
      .points{
        .points_row{
          padding-left: 70px;
          font-size: 24px;
          line-height: 35.72px;
          font-weight: 400;
          position: relative;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          span{
            display: contents;
            font-weight: 600;
          }
          &:not(:last-child){
            margin-bottom: 26px;
          }
          img{
            position: absolute;
            left: 0px;
            width: 40px;
          }
        }
      }
    }
    .title{
      font-size: 60.31px;
      font-weight: 800;
      line-height: 71.97px;
      width: 50%;
      color: #000000;
      margin-bottom: 19px;
    }
    .desc{
      font-size: 22px;
      font-weight: 500;
      line-height: 40.04px;
      color: #000000;
    }
    .image{
      width: 100%;
      border-radius: 14px;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
// Vision Mission End

// Our Clients Section Start

.our_clients {
  padding: 122px 0;
  background: #f5f5f5;
  .title {
    font-weight: 700;
    font-size: 56px;
    line-height: 67px;
    text-align: center;
    text-transform: capitalize;
    color: #1886cd;
  }
  .desc {
    font-weight: 800;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #0d0f27;
    margin-top: 10px;
  }
  .our_client_wrapper {
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    .our_client_box {
      transition: 0.6s linear;
      width: 142px;
      height: 142px;
      border-radius: 50%;
      overflow: hidden;
      // border: 9px solid #FFFFFF;
      margin-right: 50px;
      margin-bottom: 50px;
      &:nth-child(7n) {
        margin-right: 0;
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .our_client_box:hover {
      transform: scale(1.2);
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
  }
  .content_text {
    font-weight: 500;
    font-size: 20px;
    line-height: 143.84%;
    text-align: center;
    text-transform: capitalize;
    color: #0d0f27;
    letter-spacing: 0.3px;
    width: 50%;
    margin: 0 auto;
    margin-top: 30px;
  }
}

// Our Clients Section End

/* Mobile Devices */
@media screen and (max-width: 767px) {
  .about_content {
    padding: 40px 0 50px 0;
    .title {
      font-size: 22px;
      width: 100%;
    }
    .desc {
      width: 100%;
      font-size: 18px;
    }
    .about_image {
      &::after {
        right: -7px;
        bottom: -7px;
        width: 95%;
      }
    }
    .word_ceo {
      margin-top: 70px;
      flex-direction: column;
      height: auto;
      .thumbnail {
        width: 100%;
      }
      .content {
        width: 100%;
        padding: 30px 20px;
        .ceo_title {
          font-size: 36px;
          line-height: 40px;
        }
        .ceo_desc {
          padding: 30px 0 30px 0;
        }
      }
    }
  }
  .team {
    padding: 60px 0;
    .title {
      font-size: 46px;
      margin-bottom: 10px;
      line-height: 46px;
      margin-top: 30px;
    }
    .desc {
      font-size: 18px;
      line-height: 24px;
      width: 100%;
    }
    .team_wrapper {
      margin-top: 40px;
      .team_box {
        width: calc(50% - 20px);
        margin-right: 0px;
        margin: 10px;
        height: 200px;
        &:nth-child(5n) {
          margin-right: 10px;
        }
        .team_detail {
          .name {
            font-size: 16px;
          }
          .position {
            font-size: 14px;
          }
        }
      }
    }
  }
  .our_clients {
    padding: 60px 0;
    .title {
      font-size: 46px;
      margin-bottom: 0px;
      line-height: 46px;
      margin-top: 0px;
    }
    .desc {
      font-size: 18px;
      line-height: 24px;
      width: 100%;
      margin-top: 5px;
    }
    .our_client_wrapper {
      margin-top: 50px;
      .our_client_box {
        margin: 20px;
      }
    }
    .content_text {
      width: 100%;
      font-size: 18px;
    }
  }
}

/* Tablet Devices */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .about_content {
    padding: 50px 0 100px 0;
    .title {
      font-size: 42px;
      width: 100%;
    }
    .desc {
      width: 100%;
    }
    .word_ceo {
      margin-top: 70px;
      flex-direction: column;
      height: auto;
      .thumbnail {
        width: 100%;
        height: 350px;
      }
      .content {
        width: 100%;
        padding: 30px 20px;
        .ceo_title {
          font-size: 36px;
          line-height: 40px;
        }
        .ceo_desc {
          padding: 30px 0 30px 0;
        }
      }
    }
  }
  .team {
    padding: 80px 0;
    .team_wrapper {
      margin-top: 60px;
      .team_box {
        height: 250px;
        width: calc(33.33% - 17px);
        &:nth-child(5n) {
          margin-right: 25px;
        }
        &:nth-child(3n) {
          margin-right: 0px;
        }
      }
    }
  }
  .our_clients {
    padding: 60px 0;
    .our_client_wrapper {
      .our_client_box {
        margin-right: 20px;
        margin-bottom: 20px;
        &:nth-child(7n) {
          margin-right: 20px;
        }
        &:nth-child(4n) {
          margin-right: 0px;
        }
      }
    }
    .content_text {
      width: 100%;
    }
  }
}

/* Laptop Devices */
@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .our_clients {
    padding: 60px 0;
    .our_client_wrapper {
      .our_client_box {
        margin-right: 20px;
        margin-bottom: 20px;
        &:nth-child(7n) {
          margin-right: 20px;
        }
        &:nth-child(4n) {
          margin-right: 20px;
        }
      }
    }
    .content_text {
      width: 100%;
    }
  }
  .about_content {
    padding: 80px 0;
    .title {
      width: 100%;
    }
    .desc {
      width: 100%;
    }
    .word_ceo {
      .content {
        padding: 50px;
      }
    }
  }
  .team {
    padding: 80px 0;
    .team_wrapper {
      margin-top: 60px;
      .team_box {
        height: 250px;
        width: calc(25% - 19px);
        &:nth-child(5n) {
          margin-right: 25px;
        }
        &:nth-child(3n) {
          margin-right: 25px;
        }
        &:nth-child(4n) {
          margin-right: 0px;
        }
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .about_content {
    .word_ceo {
      height: 650px;
      .content {
        padding: 38px;
      }
    }
  }
}

/* Desktop Devices */
@media screen and (min-width: 1366px) and (max-width: 1400px) {
  .about_content .word_ceo .content {
    padding: 100px 70px;
  }
}

/* TV Devices */
@media screen and (min-width: 1920px) {
}

// Story, lifeStyle amd Journey Pages Heading Start

.about_heading {
  padding-top: 110px;
  width: 100%;
  .about_img_wrapper {
    width: 100%;
    height: 362px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .heading_title_wrapper {
    position: relative;
    .heading_title {
      position: absolute;
      bottom: -95px;
      left: 0;
      font-size: 50px;
      font-weight: 800;
      line-height: 69px;
      letter-spacing: 0px;
      width: 471px;
      padding: 15px 46px;
      background-color: #fff;
      box-shadow: 0px 11px 26px 0px #0000000d;
      border-radius: 15px;
    }
  }
}

@media screen and (max-width: 767px) {
  .about_heading {
    padding-top: 75px;
    .about_img_wrapper {
      height: 190px;
    }
    .heading_title_wrapper {
      .heading_title {
        width: 100%;
        line-height: 28px;
        bottom: -30px;
        font-size: 20px;
        padding: 10 20px;
      }
    }
  }
}
@media screen and (max-width: 1366px) {
  .about_heading {
    padding-top: 75px;
  }
}

// Story, lifeStyle amd Journey Pages Heading end
// Story page Start
.story_wrapper {
  padding-bottom: 155px;
  background-color: #fff;
  margin-top: 462px;
  .story_container {
    .top_img {
      margin-top: -293px;
      img {
        width: 100%;
        max-width: 514px;
      }
    }
    .story_logo {
      max-width: 356px;
      margin: 247px auto 0;
      img {
        width: 100%;
      }
    }
    .story_content {
      margin-top: -293px;
      padding: 35px;
      background-color: #fff;
      border: 1px solid #5d5a5b33;
      border-radius: 10px;
      .title {
        font-size: 25px;
        font-weight: 700;
        line-height: 39px;
        letter-spacing: 0px;
        color: #0d0f27;
        &.mb_45 {
          margin-bottom: 45px;
        }
      }
      .paragraph {
        margin-bottom: 45px;
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0px;
        color: #5d5a5b;
      }
      .mini_title {
        margin-bottom: 45px;
        font-size: 30px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0px;
        color: #000000;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .story_wrapper {
    margin-top: 280px;
    .story_container {
      .story_logo {
        margin: 50px auto 0;
      }
      .top_img {
        margin-top: -140px;
      }
      .story_content {
        margin-top: -140px;
        padding: 15px;
        .title {
          font-size: 18px;
          line-height: 26px;
          &.mb_45 {
            margin-bottom: 15px;
          }
        }
        .paragraph {
          font-size: 16px;
          line-height: 26px;
          margin-bottom: 15px;
        }
        .mini_title {
          font-size: 22px;
          margin-bottom: 15px;
        }
      }
    }
  }
  .vision_mission{
    .common_box{
      &.vision{
        .image{
          height: 288px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .story_wrapper {
    margin-top: 210px;
    .story_container {
      .story_logo {
        margin: 50px auto 0;
      }
      .story_content {
        margin-top: 50px;
        padding: 15px;
        .title {
          font-size: 18px;
          line-height: 26px;
          &.mb_45 {
            margin-bottom: 15px;
          }
        }
        .paragraph {
          font-size: 16px;
          line-height: 26px;
          margin-bottom: 15px;
        }
        .mini_title {
          font-size: 22px;
          margin-bottom: 15px;
        }
      }
    }
  }
}

// Story Page End

// Lifestyle Page Start
.lifestyle {
  .life_content {
    // margin-bottom: 56px;
    margin-top: 145px;
    .tag_line {
      font-size: 25px;
      font-weight: 700;
      line-height: 35px;
      letter-spacing: 0px;
    }
  }
  .life_style_wrapper {
    background-color: #fff;
    margin-top: 247px;
    padding-bottom: 146px;
  }
  .life_box {
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 10px;
    border: 10px solid #fff;
    position: relative;
    margin-bottom: 30px;
    &.top {
      margin-top: -191px;
    }
    .life_img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .life_box_content {
      padding: 40px;
      position: absolute;
      width: 100%;
      z-index: 1;
      bottom: 0;
      left: 0;
      border-radius: 10px;
      background-color: rgba(60, 59, 59, 0.565);
      backdrop-filter: blur(3px);
      .life_title {
        font-size: 30px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0px;
        color: #fff;
        margin-bottom: 15px;
      }
      .life_desc {
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0px;
        color: #fff;
      }
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .lifestyle {
    .life_box {
      .life_box_content {
        .life_title {
          font-size: 20px;
          line-height: 26px;
          margin-bottom: 5px;
        }
        .life_desc {
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
        }
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .lifestyle {
    .life_box {
      &.right {
        margin-top: 0;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .lifestyle {
    .life_content {
      margin-top: 70px;
    }
    .life_box {
      .life_box_content {
        padding: 20px;
        .life_title {
          font-size: 20px;
          line-height: 26px;
          margin-bottom: 5px;
        }
        .life_desc {
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
        }
      }
    }
  }
}
@media screen and (max-width: 560px) {
  .lifestyle {
    .life_style_wrapper {
      padding-bottom: 0px;
    }
    .life_content {
      margin-top: 70px;
      .tag_line {
        font-size: 20px;
        line-height: 26px;
      }
    }
    .life_box {
      .life_box_content {
        padding: 20px;
        .life_title {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 5px;
        }
        .life_desc {
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
        }
      }
    }
  }
}

// Lifestyle Page End
// Journy Page Start

.journy {
  margin-bottom: 100px;
  .journy_title {
    margin-top: 145px;
    font-size: 25px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0px;
  }
  .journy_progress {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 103px;
    position: relative;
    &::after {
      content: "";
      width: 5px;
      height: calc(100% - 130px);
      background-color: #0d0f27;
      position: absolute;
      top: 60px;
      left: 50%;
      transform: translateX(-50%);
    }
    .progress_row {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      &:not(:last-child) {
        margin-bottom: 75px;
      }
      &::after {
        content: "";
        height: 46px;
        width: 46px;
        background-color: #1886cd;
        border: 6px solid #ffffff;
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50%;
        z-index: 1;
      }
      &.swap {
        flex-direction: row-reverse;
        .content {
          align-items: flex-end;
          padding-left: 0px;
          padding-right: 67px;
          text-align: right;
          .title {
            &::after {
              left: unset;
              right: 0;
            }
          }
        }
        .prog_img {
          justify-content: flex-start;
          padding-right: 0px;
          padding-left: 67px;
        }
      }
      .prog_img {
        width: 35%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 67px;
        margin-top: -45px;
        img {
          max-width: 207px;
          width: 100%;
          height: auto;
        }
      }
      .content {
        padding-left: 67px;
        width: 37%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        .title {
          font-size: 34px;
          font-weight: 800;
          line-height: 41px;
          letter-spacing: 0px;
          color: #1886cd;
          position: relative;
          margin-bottom: 30px;
          &::after {
            content: "";
            height: 3px;
            width: 162px;
            border-bottom: 1px dashed #000;
            position: absolute;
            bottom: -13px;
            left: 0;
          }
        }
        .desc {
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0px;
          color: #000;
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .journy {
    .journy_title {
      margin-top: 130px;
      font-size: 20px;
      line-height: 28px;
    }
    .journy_progress {
      margin-top: 60px;
      .progress_row {
        .content {
          width: 50%;
        }
        .prog_img {
          width: 50%;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .journy {
    .journy_title {
      margin-top: 60px;
    }
    .journy_progress {
      .progress_row {
        .content {
          .title {
            font-size: 24px;
            margin-bottom: 20px;
            &::after {
              bottom: -7px;
            }
          }
          .desc {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .journy {
    .journy_title {
      margin-top: 60px;
    }
    .journy_progress {
      &::after {
        width: 3px;
      }
      .progress_row {
        & :not(:last-child) {
          margin-bottom: 35px;
        }
        &::after {
          content: "";
          height: 26px;
          width: 26px;
          border: 3px solid #ffffff;
          top: 35px;
        }
        &.swap {
          .content {
            padding-right: 20px;
          }
          .prog_img {
            padding-left: 20px;
          }
        }
        .prog_img {
          padding-right: 20px;
        }
        .content {
          padding-left: 20px;
          .title {
            font-size: 20px;
            margin-bottom: 20px;
            &::after {
              bottom: -7px;
              width: 80px;
            }
          }
          .desc {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }
}

// Journy Page End

//  Our Culture start

.ourculture {
  margin-top: 100px;
  .culture_heading_wrapper {
    position: relative;
    .culture_title {
      left: 0;
      font-size: 50px;
      font-weight: 800;
      line-height: 69px;
      letter-spacing: 0px;
      width: 380px;
      padding: 15px 46px;
      background-color: #1886cd;
      color: #ffffff;
      box-shadow: 0px 11px 26px 0px rgba(0, 0, 0, 0.0509803922);
      border-radius: 15px;
    }
  }
  .culture_img_wrapper {
    margin-top: 50px;
    overflow: hidden;
    .culture_img {
      // height: 416px;
      // width: 100%;
      position: center;
      margin-bottom: 25px;
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: 10px;
      }
    }
  }
}

//  Our Culture end
