footer{
    .social_icons{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        background-color: #fff;
        .social_box{
            width: 16.66%;
            padding: 30px 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            background-color: #fff;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            text-decoration: none;
            color: #333333;
            border-right: 3px solid #00000008;
            &:nth-child(1){
                border-bottom: 3px solid #4064AC;
            }
            &:nth-child(2){
                border-bottom: 3px solid #FF0000;
            }
            &:nth-child(3){
                border-bottom: 3px solid #1769FF;
            }
            &:nth-child(4){
                border-bottom: 3px solid #EE6996;
            }
            &:nth-child(5){
                border-bottom: 3px solid #1DA1F2;
            }
            &:nth-child(6){
                border-bottom: 3px solid #007EBB;
                border-right: 0;
            }
            img{
                height: 16px;
                margin-right: 15px;
            }
        }
    }
    .footer_wrapper{
        background-image: url('./../../assets/images/footer.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding: 100px 0;
        position: relative;
        &::after{
            content: '';
            height: 1px;
            width: 100%;
            background-color: #ffffff30;
            left: 0;
            top: 320px;
            transform: translateY(-50%);
            position: absolute;
        }
        .top{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 180px;
            .logo{
                max-width: 445px;
                width: 100%;
                height: auto;
                img{
                    width: 100%;
                }
            }
            .content{
                position: relative;
                padding-left: 50px;
                margin-left: 150px;
                &::after{
                    content: '';
                    height: calc(100% - 30px);
                    width: 1px;
                    background-color: #ffffff50;
                    left: 0;
                    top: 55%;
                    transform: translateY(-50%);
                    position: absolute;
                }
                .title{
                    font-weight: 800;
                    font-size: 50px;
                    line-height: 76px;
                    letter-spacing: -0.8px;
                    color: #FFFFFF;
                    width: max-content;
                }
                .desc{
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 28px;
                    color: #9CA3B1;
                    width: 100%;
                }
            }
        }
        .bottom{
            // .link_title{
            //     font-weight: 700;
            //     font-size: 22px;
            //     line-height: 26px;
            //     letter-spacing: 0.4px;
            //     color: #FFFFFF;
            //     margin-bottom: 25px;
            // }
            .links{
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                
                a{
                    display: block;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 33px;
                    letter-spacing: -0.1px;
                    color: #9CA3B1;
                    text-decoration: none;
                    outline: none;
                    user-select: none;
                    transition: 0.3s;
                    &:hover{
                        color: #fff;
                    }
                }
            }
        }
        // .update_form{
        //     input{
        //         background: #FFFFFF;
        //         border: 1px solid #FFFFFF;
        //         border-radius: 6px;
        //         padding: 20px 25px;
        //         width: 100%;
        //         outline: none;
        //         font-weight: 400;
        //         font-size: 16px;
        //         line-height: 19px;
        //         letter-spacing: -0.2px;
        //         color: #5D5A5B;
        //     }
        //     button{
        //         background: #1886CD;
        //         border-radius: 31px;
        //         outline: none;
        //         user-select: none;
        //         padding: 10px 30px;
        //         border: 0;
        //         margin-top: 20px;
        //         font-weight: 500;
        //         font-size: 20px;
        //         line-height: 30px;
        //         text-align: center;
        //         color: #FFFFFF;
        //     }
        // }
    }
    .copy_right{
        display: flex;
        justify-content: center;
        align-content: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
        background: #1886CD;
        p{
            margin: 0;
            padding: 20px;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: #FFFFFF;
        }
    }
}

/* Mobile Devices */
@media screen and (max-width: 767px) {
    footer{
        overflow: hidden;
        .social_icons{
            .social_box{
                width: 33.33%;
            }
        }
        .footer_wrapper{
            padding: 50px 0;
            &::after{
                top: 340px;
            }
            .top{
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-bottom: 30px;
                .logo{
                    margin-bottom: 40px;
                }
                .content{
                    margin: 0;
                    padding-left: 0;
                    &::after{
                        display: none;
                    }
                    .title{
                        width: 100%;
                        font-size: 30px;
                    }
                    .desc{
                        font-size: 18px;
                        line-height: 22px;
                    }
                }
            }
            .bottom{
                // .link_title{
                //     margin-top: 40px;
                //     margin-bottom: 10px;
                // }
                .links{
                    display: block;
                }
            }
        }
    }
}

/* Tablet Devices */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    footer{
        .footer_wrapper{
            padding: 50px 0;
            &::after{
                top: 340px;
            }
            .top{
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-bottom: 30px;
                .logo{
                    margin-bottom: 40px;
                    width: 100%;
                    max-width: 100%;
                    margin-bottom: 25px;
                    img{
                        width: 450px;
                    }
                }
                .content{
                    margin: 0;
                    padding-left: 0;
                    &::after{
                        display: none;
                    }
                    .title{
                        width: 100%;
                        font-size: 40px;
                        font-weight: 300;
                        letter-spacing: 0.3px;
                    }
                    .desc{
                        font-size: 24px;
                        line-height: 30px;
                        font-weight: 300;
                    }
                }
            }
            .bottom{
                // .link_title{
                //     margin-top: 40px;
                //     margin-bottom: 10px;
                // }
                .links{
                    margin-top: 60px;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}

/* Laptop Devices */
@media screen and (min-width: 1024px) and (max-width: 1365px) {
    footer{
        .footer_wrapper{
            padding: 50px 0;
            .top{
                padding-bottom: 100px;
                .content{
                    margin-left: 50px;
                    .title{font-size: 40px;}
                    .desc{font-size: 18px;}
                }
            }
            &::after{
                top: 220px;
            }
        }
    }
}

/* Desktop Devices */
@media screen and (min-width: 1366px) and (max-width: 1919px) {}

/* TV Devices */
@media screen and (min-width: 1920px) {}