.lets_talk{
    background: #FFFFFF;
    border-radius: 20px;
    padding: 33px;
    margin-top: 18px;
    .title{
        font-weight: 700;
        font-size: 36px;
        line-height: 26px;
        display: flex;
        align-items: center;
        letter-spacing: -0.2px;
        color: #0D0F27;
    }
    input, textarea{
        background: rgba(93, 90, 91, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 6px;
        width: 100%;
        padding: 15px 20px;
        margin-top: 26px;
    }
    button{
        font-family: roboto;
        padding: 8px 20px;
        border-radius: 50px;
        background-color: #1886CD;
        border: 1px solid #1886CD;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        outline: none;
        color: #fff;
        margin-top: 20px;
    }
}

/* Mobile Devices */
@media screen and (max-width: 767px) {
    .lets_talk{
        padding: 20px;
        .title{
            font-size: 28px;
        }
    }
}

/* Tablet Devices */
@media screen and (min-width: 768px) and (max-width: 1023px) {}

/* Laptop Devices */
@media screen and (min-width: 1024px) and (max-width: 1365px) {
    .lets_talk{
        .title{
            font-size: 32px;
        }
    }
}

/* Desktop Devices */
@media screen and (min-width: 1366px) and (max-width: 1919px) {}

/* TV Devices */
@media screen and (min-width: 1920px) {}