.work_details{
    margin-bottom: 80px;
    .about_project{
        margin-top: 50px;
        .title{
            font-size: 30px;
            font-weight: 700;
            line-height: 38px;
            letter-spacing: 0px;
            color: #000;
            margin-bottom: 15px;
        }
        .desc{
            font-size: 22px;
            font-weight: 400;
            line-height: 29px;
            letter-spacing: 0.5px;
            color: #000;
        }
    }
    .tech_used{
        margin-top: 50px;
        .title{
            font-size: 30px;
            font-weight: 700;
            line-height: 38px;
            letter-spacing: 0px;
            margin-bottom: 15px;
            color: #000;
        }
        .tech_wrapper{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .tech_title{
                padding: 6px 20px;
                background-color: #dfdfdf;
                border-radius: 50px;
                margin-right: 10px;
                font-size: 16px;
            }
        }
        .download_app{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 20px;
            a{
                width: 120px;
                margin-right: 10px;
                img{
                    width: 100%;
                }
            }
        }
    }
    .work_img{
        margin-top: 80px;
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 10px;
        img{
            width: 100%;
        }
    }
}
@media screen and (max-width: 540px) {
    .work_details{
        margin-bottom: 30px;
        .about_project{
            margin-top: 0;
            .title{
                font-size: 22px;
                line-height: 28px;
            }
            .desc{
                font-size: 14px;
                line-height: 20px;
            }
        }
        .tech_used{
            margin-top: 30px;
            .title {
                font-size: 22px;
            }
        }
        .work_img{
            margin-top: 30px;
        }
    }    
}