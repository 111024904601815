.contact_me{
    .title{
        font-weight: 700;
        font-size: 56px;
        line-height: 67px;
        text-transform: capitalize;
        color: #1886CD;
    }
    .tag{
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 70px;
    }
    .contact_box{
        padding: 30px;
        border-radius: 18px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 30px;
        width: calc(100% - 100px);
        &.address{
            background: #62FB0420;
        }
        &.mail{
            background: #FBD30420;
        }
        &.call{
            background: #5E92F820;
        }
        .icon{
            width: 56px;
            height: 56px;
            min-width: 56px;
            min-height: 56px;
            background-color: #fff;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 20px;
            img{
                width: 20px;
                height: auto;
            }
        }
        .text{
            font-weight: 700;
            font-size: 20px;
            line-height: 32px;
            color: #0D0F27;
        }
    }
}

/* Mobile Devices */
@media screen and (max-width: 767px) {
    .contact_me{
        margin-bottom: 50px;
        .title{
            font-size: 46px;
            margin-bottom: 0;
            line-height: 46px;
        }
        .tag{
            font-size: 18px;
            margin-top: 0;
            margin-bottom: 10px;
        }
        .contact_box{
            width: 100%;
            margin-bottom: 15px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 20px;
            .icon{
                margin-bottom: 10px;
                width: 36px;
                height: 36px;
                min-width: 36px;
                min-height: 36px;
                img{
                    width: 16px;
                }
            }
            .text{
                font-size: 18px;
                line-height: 24px;
            }
        }
    }
}

/* Tablet Devices */
@media screen and (min-width: 768px) and (max-width: 1023px) {}

/* Laptop Devices */
@media screen and (min-width: 1024px) and (max-width: 1365px) {
    .contact_me{
        .contact_box{
            width: calc(100% - 0px);
            padding: 25px;
        }
    }
}

/* Desktop Devices */
@media screen and (min-width: 1366px) and (max-width: 1919px) {}

/* TV Devices */
@media screen and (min-width: 1920px) {}