.nav_container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(0px);
    z-index: 9999999;
    transition: 0.8s;
    &.hidden{
        transform: translateY(-150px);
        transition: 0.8s;
    }
    &.toggled{
        transform: translateY(0px);
        transition: 0.8s;
    }
}

// Best Offer Start

.best_offer{
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-bottom: 95px;
    position: fixed;
    width: 100%;
    z-index: 9999;
    top: 0;
    background: #ffffffd1;
    backdrop-filter: blur(3px);
    .offer_wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 15px;
        .offer_text{
            color: #1886CD;
            font-weight: 600;
            font-size: 15.3493px;
            line-height: 20px;
            text-transform: uppercase;
            position: relative;
            &::after, &::before{
                content: '';
                left: -15px;
                top: 6px;
                width: 8px;
                height: 8px;
                background-color: #1886CD;
                position: absolute;
                z-index: 1;
                border-radius: 5PX;
            }
            &::before{
                left: unset;
                right: -15px;
            }
        }
        .call{
            background-color: #1886CD;
            padding: 6px 0px 6px 10px;
            position: relative;
            &::after{
                content: '';
                left: 120px;
                width: 500px;
                height: 100%;
                background-color: #1886CD;
                position: absolute;
                top: 0;
                z-index: -1;
            }
            .call_wrapper{
                img{
                    width: 18px;
                    margin-right: 10px;
                    margin-top: -4px;
                    margin-left: 5px;
                }
                span{
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                    text-transform: uppercase;
                    color: #FFFFFF;
                }
            }
        }
    }
}

// Best Offer End

// Navigation Start

.navigation{
    padding: 30px 0;
    position: fixed;
    width: 100%;
    // top: 36px;
    z-index: 9999;
    background: #ffffffd1;
    backdrop-filter: blur(3px);
    .nav_wrapper{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo{
            max-width: 163px;
            min-width: 100px;
            img{
                width: 100%;
            }
        }
        .links{
            display: flex;
            justify-content: center;
            align-items: center;
            .drop_wrapper{
                position: relative;
                z-index: 1;
                display: inline-block;
                .dropdown_content{
                    position: absolute;
                    top:61px;
                    box-shadow: 0px 4px 25px 0px #0000000D;
                    border-radius: 10px;
                    padding: 10px 0;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    background-color: #fff;
                    min-width: 209px;
                    transition: 0.3s;
                    transform: scaleY(0);
                    transform-origin: top;
                    &.active{
                        transform: scaleY(1);
                    }
                    .content_row{
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        margin-bottom: 13px;
                        user-select: none;
                        padding: 8px 20px;
                        margin-bottom: 0;
                        cursor: pointer;
                        font-size: 16px;
                        width: 100%;
                        &:hover{
                            background-color: #f5f5f5;
                        }
                        &::after{
                            display: none;
                        }
                        &:last-child{
                            margin-bottom: 0px;
                        }
                        img{
                            margin-right: 10px;
                            width: 24px;
                        }
                        span{
                            white-space: nowrap;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 21px;
                            letter-spacing: 0px;
                            color: #24242D;
                        }
                    }
                }

            }
            button{
                img{
                    transition: .3s;
                    margin-left: 5px;
                    margin-top: 5px;
                    transform: rotate(180deg);
                }
                &.dropped{
                    img{
                        transform: rotate(180deg);
                    }
                }
            }
            a, button{
                font-family: roboto;
                font-weight: 500;
                font-size: 20px;
                text-decoration: none;
                padding: 10px 15px;
                line-height: 23px;
                display: flex;
                align-items: center;
                color: #5D5A5B;
                transition: 0.3s;
                position: relative;
                border: 0;
                background-color: transparent;

                &::after{
                    content: '';
                    left: 5px;
                    top: 19px;
                    width: 6px;
                    height: 6px;
                    background-color: #1886CD;
                    position: absolute;
                    z-index: 1;
                    border-radius: 5PX;
                    transition: 0.3s;
                    opacity: 0;
                }
                &.active, &:hover{
                    color: #1886CD;
                    &::after{
                        opacity: 1;
                    }
                }
                img{
                    transition: .3s;
                    margin-left: 5px;
                    margin-top: 5px;
                }
                &:hover{
                    img{
                        transform: rotate(180deg);
                    }
                    .dropdown_content{
                        transform: scaleY(1);
                    }
                }
                .dropdown_content{
                    position: absolute;
                    top:41px;
                    left: 0;
                    box-shadow: 0px 4px 25px 0px #0000000D;
                    border-radius: 10px;
                    padding: 10px 0;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    background-color: #fff;
                    min-width: 209px;
                    transition: 0.3s;
                    transform: scaleY(0);
                    transform-origin: top;
                    
                    .content_row{
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        margin-bottom: 13px;
                        user-select: none;
                        padding: 8px 20px;
                        margin-bottom: 0;
                        cursor: pointer;
                        font-size: 16px;
                        width: 100%;
                        &:hover{
                            background-color: #f5f5f5;
                        }
                        &::after{
                            display: none;
                        }
                        &:last-child{
                            margin-bottom: 0px;
                        }
                        img{
                            margin-right: 10px;
                            width: 24px;
                        }
                        span{
                            white-space: nowrap;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 21px;
                            letter-spacing: 0px;
                            color: #24242D;
                        }
                    }
                }
            }
        }
        .action{
            justify-content: flex-end;
            align-items: center;
            transition: 0.5s linear;
            .case_animation{
                animation: animation 1s  infinite ;
                color: #000;
            }
            @keyframes animation {
                
                0%{background-color: #1886CD; color: #ffffff;}
                50%{background-color: #fff; color: #000000;}
                100%{background-color: #1886CD;color: #ffffff;}

            }
            button, a{
                font-family: roboto;
                padding: 8px 20px;
                border-radius: 50px;
                background: #FFFFFF;
                border: 1px solid #1886CD;
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                text-align: center;
                color: #1886CD;
                text-decoration: none;
                outline: none;
                display: inline-block;
                &::after{
                    opacity: 0;
                    display: none;
                }
                &:nth-child(2){
                    background-color: #1886CD;
                    color: #fff;
                    margin-left: 15px;
                }
                &:nth-child(3){
                    background-color: #1886CD;
                    margin-left: 15px;
                    padding: 8px 12px;
                }
            }
        }
    }
}


// Navigation End

// Mobile Nav Start

.mobile_nav{
    display: none;
    padding: 20px 15px;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 9999;
    background: #ffffffd1;
    backdrop-filter: blur(3px);
    .nav_wrapper{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo{
            max-width: 170px;
            min-width: 100px;
            img{
                width: 100%;
            }
        }
        .toggle{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            &.active{
                span{
                    margin: -1px 0;
                    &:nth-child(1){
                        transform: rotate(45deg);
                    }
                    &:nth-child(2){
                        transform:scale(0);
                    }
                    &:nth-child(3){
                        transform: rotate(-45deg);
                    }
                }
            }
            span{
                display: block;
                transition: 0.3s;
                height: 2px;
                width: 35px;
                background-color: #1886CD;
                margin: 3px 0;
            }
        }
        .links{
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 75px;
            left: 0;
            background-color: #ffffff;
            transform: translate(-100%);
            flex-direction: column;
            width: 100%;
            height: calc(100vh - 75px);
            transition: 0.3s;
            &.active{
                transform: translate(0%);
                overflow-x: auto;
            }
            button{
                border: 0;
                background-color: transparent;
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                font-family: roboto;
                font-weight: 500;
                font-size: 20px;
                text-decoration: none;
                padding: 15px;
                line-height: 23px;
                display: flex;
                text-align: center;
                align-items: center;
                color: #5D5A5B;
                transition: 0.3s;
                position: relative;
            }
             a{
                font-family: roboto;
                font-weight: 500;
                font-size: 20px;
                text-decoration: none;
                padding: 15px;
                line-height: 23px;
                display: flex;
                text-align: center;
                align-items: center;
                color: #5D5A5B;
                transition: 0.3s;
                position: relative;
                &::after{
                    content: '';
                    left: 0px;
                    top: 23px;
                    width: 6px;
                    height: 6px;
                    background-color: #1886CD;
                    position: absolute;
                    z-index: 1;
                    border-radius: 5PX;
                    transition: 0.3s;
                    opacity: 0;
                }
                &.active, &:hover{
                    color: #1886CD;
                    &::after{
                        opacity: 1;
                    }
                }
            }
            .drop_wrapper{
                position: relative;
                z-index: 1;
                display: inline-block;
                .link_button{
                    img{
                        margin: 5px;
                        transition: 0.3s;
                    }
                    &.dropped{
                        img{
                            transform: rotate(180deg);
                        }
                    }
                }
                .dropdown_content{
                    position: relative;
                    box-shadow: 0px 4px 25px 0px #0000000D;
                    border-radius: 10px;
                    padding: 20px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    background-color: #fff;
                    transition: 0.3s;
                    display: none;
                    transform-origin: top;
                    &.active{
                        display: block;
                        position: relative;
                    }
                    .content_row{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 0px;
                        user-select: none;
                        padding: 10px;
                        font-size: 18px;
                        width: max-content;
                        margin: 0 auto;
                        &::after{
                            top: 18px;
                        }
                        cursor: pointer;
                        &:last-child{
                            margin-bottom: 0px;
                        }
                        img{
                            margin-right: 10px;
                            width: 24px;
                        }
                        span{
                            white-space: nowrap;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 21px;
                            letter-spacing: 0px;
                            color: #24242D;
                        }
                    }
                }

            }
            .action{
                margin-top: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                button, a{
                    font-family: roboto;
                    padding: 8px 20px;
                    border-radius: 50px;
                    background: #FFFFFF;
                    border: 1px solid #1886CD;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 30px;
                    text-align: center;
                    color: #1886CD;
                    text-decoration: none;
                    outline: none;
                    margin-bottom: 20px;
                    &.active, &:hover{
                        &::after{
                            display: none;
                        }
                    }
                    &:nth-child(2){
                        background-color: #1886CD;
                        color: #fff;
                        margin-left: 0px;
                    }
                    &:nth-child(3){
                        background-color: #1886CD;
                        margin-left: 0px;
                        padding: 8px 12px;
                    }
                }
            }
        }
    }
}

// Mobile Nav End


/* Desktop Devices */
@media screen and (min-width: 1366px) and (max-width: 1399px) {
    .navigation{
        .nav_wrapper{
            .links{
                a,button{
                    font-size: 16px;
                }
            }
        }
    }
}
/* Mobile Devices */
@media screen and (max-width: 1366px) {
    .best_offer{display: none;}
    .navigation{display: none;}
    .mobile_nav{display: block;}
}


/* Tablet Devices */
@media screen and (min-width: 768px) and (max-width: 1023px) {}

/* Laptop Devices */
@media screen and (min-width: 1024px) and (max-width: 1399px) {
    .mobile_nav{
        .nav_wrapper{
            .links{
                .action{
                    flex-direction: row;
                    button, a{
                        margin: 0 10px;
                    }
                }
            }
        }
    }
}



/* TV Devices */
@media screen and (min-width: 1920px) {}