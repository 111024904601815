.client_content{
    background-color: #fff;
    padding: 106px 0 80px 0;
    .owl-dots{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        margin-bottom: 10px;
        .owl-dot{
            background-color: #fff;
            height: 16px;
            width: 16px;
            margin: 0 5px;
            border-radius: 20px;
            position: relative;
            z-index: 1;
            &::after{
                content: "";
                height: 20px;
                width: 20px;
                background-color: #fff;
                border: 1px solid #1886cd;
                position: absolute;
                top: -2px;
                left: -2px;
                border-radius: 50%;
                z-index: 0;
                opacity: 0;
                transition: 0.3s;
            }
            &::before{
                content: "";
                height: 14px;
                width: 14px;
                background-color: #1886cd;
                position: absolute;
                top: 1px;
                left: 1px;
                border-radius: 50%;
                z-index: 1;
                opacity: 0;
                transition: 0.3s;
            }
            &.active{
                background: #fff;
                &::after, &::before{
                    opacity: 1;
                }
            }
        }
    }
    .title{
        font-weight: 700;
        font-size: 50px;
        line-height: 138.34%;
        text-align: center;
        color: #000000;
        width: 75%;
        margin: 0 auto 22px auto;
        span{
            color: #1886CD;
        }
    }
    .desc{
        font-weight: 400;
        font-size: 20px;
        line-height: 138.34%;
        text-align: center;
        color: #000000;
        width: 65%;
        margin: 0 auto;
        margin-bottom: 60px;
    }
    .about_image{
        max-width: 877px;
        max-height: 351px;
        width: 100%;
        margin: 0 auto;
        border-radius: 10px;
        position: relative;
        &::after{
            content: '';
            height: 90%;
            width: 90%;
            position: absolute;
            bottom: -12px;
            right: -12px;
            z-index: 0;
            background: #1886CD;
            border-radius: 10px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: relative;
            z-index: 1;
            border-radius: 10px;
        }
    }
}

// Clients Speak

.let_speak_client{
    background-color: #F5F5F5;
    padding: 150px 0 170px 0;
    .title{
        font-weight: 700;
        font-size: 56px;
        line-height: 67px;
        text-transform: capitalize;
        color: #1886CD;
    }
    .desc{
        font-weight: 700;
        font-size: 30px;
        line-height: 138.34%;
        letter-spacing: 1px;
        color: #0C0F31;
        margin-top: 10px;
        margin-bottom: 105px;
    }
    .client_box{
        background: #FFFFFF;
        border-radius: 20px;
        padding: 23px;
        width: 100%;
        margin-right: 30px;
        margin-bottom: 25px;
        .client_img{
            max-width: 425px;
            max-height: 265px;
            height: 100%;
            width: 100%;
            border-radius: 13px;
            position: relative;
            img{
                height: 100%;
                border-radius: 13px;
                width: 100%;
                object-fit: cover;
            }
            .button{
                position: absolute;
                right: 25px;
                bottom: -33px;
                box-shadow: 0px 0px 25px 3px rgba(0, 110, 255, 0.8);
                background: radial-gradient( rgba(0, 106, 255, 0.8) 60%, rgb(255, 255, 255) 62%);
                border-radius: 50%;
                &::after {
                    content: "";
                    position: absolute;
                  }
                  &:before {
                    content: "";
                    position: absolute;
                    width: 155%;
                    height: 155%;
                    -webkit-animation-delay: 0s;
                    animation-delay: 0s;
                    animation-duration:0.1s;
                    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
                    -webkit-animation: pulsate1 0.1s;
                    animation: pulsate1 1s;
                    -webkit-animation-direction: forwards;
                    animation-direction: forwards;
                    -webkit-animation-iteration-count: infinite;
                    animation-iteration-count: infinite;
                    -webkit-animation-timing-function: steps;
                    animation-timing-function: steps;
                    opacity: 1;
                    border-radius: 50%;
                    border: 14px solid rgba(255, 255, 255, .75);
                    top: -28%;
                    left: -28%;
                    background: rgba(0, 0, 0, 0);
                }
            }
        }
        .content{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 30px;
            margin-bottom: 10px;
            .avtar{
                height: 75px;
                width: 75px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 20px;
                img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
            .details{
                .name{
                    font-weight: 800;
                    font-size: 23.0829px;
                    line-height: 23px;
                    letter-spacing: -0.177561px;
                    color: #0C0F31;
                }
                .address{
                    font-weight: 400;
                    font-size: 14.2049px;
                    line-height: 21px;
                    color: #0C0F31;
                    padding-top: 2px;
                }
            }
        }
        transition: 0.6s linear;
    }
    .client_box:hover{
        transform: scale(1.1);
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

    }
}

/* Mobile Devices */
@media screen and (max-width: 767px) {
    .client_content{
        padding: 60px 0;
        .title{
            font-size: 34px;
            line-height: 42px;
            margin-bottom: 20px;
            width: 100%;
        }
        .desc{
            font-size: 18px;
            line-height: 24px;
            width: 100%;
        }
        .about_image{
            &::after{
                right: -7px;
                bottom: -7px;
            }
        }
    }
    .let_speak_client{
        padding: 60px 0;
        .title{
            font-size: 34px;
            line-height: 42px;
            margin-bottom: 0px;
            width: 100%;
        }
        .desc{
            font-size: 18px;
            line-height: 24px;
            width: 100%;
            margin-bottom: 50px;
        }
        .client_box{
            width: calc(100%);
            border-radius: 10px;
            padding: 15px;
            margin-bottom: 15px;
            .client_img{
                .button{
                    bottom: -20px;
                    width: 40px;
                }
            }
            .content{
                margin-top: 25px;
                .avtar{
                    width: 50px;
                    height: 50px;
                }
                .details{
                    .name{
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

/* Tablet Devices */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .client_content{
        padding: 60px 0;
        .title{
            width: 100%;
        }
        .desc{
            width: 100%;
        }
    }
    .let_speak_client{
        padding: 60px 0;
        .desc{
            margin-bottom: 50px;
        }
        .client_box{
            padding: 15px;
            .content{
                margin-top: 25px;
                margin-bottom: 0;
                .avtar{
                    height: 50px;
                    width: 50px;
                }
            }
        }
    }
}

/* Laptop Devices */
@media screen and (min-width: 1024px) and (max-width: 1365px) {
    .client_content{
        padding: 60px 0;
        .title{
            width: 100%;
        }
        .desc{
            width: 100%;
        }
    }
    .let_speak_client{
        padding: 60px 0;
        .desc{
            margin-bottom: 50px;
        }
        .client_box{
            padding: 15px;
            .content{
                margin-top: 25px;
                margin-bottom: 0;
                .avtar{
                    height: 50px;
                    width: 50px;
                }
            }
        }
    }
}

/* Desktop Devices */
@media screen and (min-width: 1366px) and (max-width: 1919px) {}

/* TV Devices */
@media screen and (min-width: 1920px) {}