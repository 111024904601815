// Details Section Start

.details_container {
    background: #FBFAFA;
    padding: 210px 0 30px 0;
  .page_links {
    font-weight: 700;
    font-size: 15.3493px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #5d5a5b;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    a {
      text-decoration: none;
      color: #1886cd;
      padding: 0 5px;
    }
    span {
      padding: 0 5px;
    }
  }
  .content{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    .logo{
        height: 96px;
        width: 96px;
        border-radius:50%;
        overflow: hidden;
        margin-bottom: 45px;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    .title{
        font-weight: 600;
        font-size: 72px;
        line-height: 86px;
        letter-spacing: -0.025em;
        color: #0D0F27;
    }
    .desc{
        margin: 25px 0 20px;
        font-weight: 500;
        font-size: 20px;
        line-height: 148.84%;
        color: #000000;
    }
    .platform {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span {
            display: block;
            border-radius: 18.5px;
            padding: 5px 15px;
            margin: 0 6px;
            &.web {
                text-decoration: none;
                color: #1886cd;
                background: rgba(24, 134, 205, 0.1);
                margin-left: 0;
            }
            &.ios {
            color: #0d0f27;
            background: rgba(0, 0, 0, 0.1);
        }
        &.app {
            color: #79c257;
            background: rgba(121, 194, 87, 0.2);
        }
        }
    }
    .industries{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 47px;
        .ind_data{
            background: #0000001A;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            padding: 8px 15px;
            border-radius: 50px;
            color: #5D5A5B;
            white-space: nowrap;
        }
    }

  }
  .detail_image{
    width: 100%;
    position: relative;
    z-index: 1;
    &::after{
        content: '';
        height: 100%;
        width: 100%;
        background: linear-gradient(rgba(0, 0, 0, 0) 50%, #fbfafa 80%);
        position: absolute;
        top: 0;
        left: 0;
    }
    img{
        width: 100%;
    }
  }
}

// Details Section End

// Requirement Section Start

.requirement{
    background: #FBFAFA;
    padding: 60px 0;
    .requirement_wrapper{
        padding: 40px 162px;
        background: #1886CD;
        border-radius: 19px;
        .title{
            font-weight: 700;
            font-size: 48.0001px;
            line-height: 57px;
            text-align: center;
            text-transform: capitalize;
            color: #FFFFFF;
            margin-bottom: 48px;
        }
        .desc{
            font-weight: 500;
            font-size: 26px;
            line-height: 170.84%;
            text-align: center;
            color: #FFFFFF;
        }
    }
}

// Requirement Section End

// Challenges Section Start

.challenges{
    background: #FBFAFA;
    padding: 60px 0 100px;
    .title{
        font-weight: 700;
        font-size: 48.0001px;
        line-height: 57px;
        text-align: left;
        text-transform: capitalize;
        color: #1886CD;
        margin-bottom: 30px;
        &.title2{
            margin-top: 70px;
        }
    }
    .desc{
        font-weight: 500;
        font-size: 26px;
        line-height: 194.84%;
        color: #0D0F27;
    }
    .challenge_wrapper{
        border: 1px solid #00000020;
        border-radius: 10px;
        background-color: transparent;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 20px 50px;
        margin-bottom: 25px;
        .icon{
            margin-right: 30px;
        }
        .text{
            font-weight: 400;
            font-size: 24px;
            line-height: 148.84%;
            color: #0D0F27;
        }
    }
}

// Challenges Section End

// UseCases Section Start

.use_case{
    padding: 90px 0 0 0;
    background: #0D0F27;
    .content{
        margin-top: 30px;
        .title{
            font-weight: 600;
            font-size: 48.0001px;
            line-height: 57px;
            text-align: left;
            text-transform: capitalize;
            color: #FFFFFF;
        }
        .desc{
            font-weight: 500;
            font-size: 18px;
            line-height: 148.84%;
            color: #FFFFFF;
            margin-top: 15px;
        }
        .usecase_box{
            border: 1px solid #ffffff20;
            border-radius: 10px;
            margin-top: 80px;
            margin-bottom: 128px;
            .usecase_row{
                border-bottom: 1px solid #ffffff20;
                background-color: transparent;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 20px 50px;
                margin-bottom: 0px;
                .icon{
                    margin-right: 25px;
                }
                .text{
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 148.84%;
                    color: #fff;
                }
            }
        }
    }
    .usecase_image{
        width: 100%;
        margin-bottom: 50px;
        img{
            width: 75%;
            margin-left: 60px;
        }
    }
}

// UseCases Section End

// Result Section Start

.results{
    padding: 120px 0 96px 0;
    background-color: #FBFAFA;
    .title{
        font-weight: 600;
        font-size: 48.0001px;
        line-height: 57px;
        text-align: left;
        text-transform: capitalize;
        color: #0D0F27;
        margin-bottom: 40px;
    }
    .result_wrapper{
        height: calc(100% - 30px);
        border: 1px solid #00000020;
        border-radius: 10px;
        background-color: transparent;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 20px 50px;
        margin-bottom: 25px;
        .icon{
            margin-right: 30px;
        }
        .text{
            font-weight: 400;
            font-size: 24px;
            line-height: 148.84%;
            color: #0D0F27;
        }
    }
}

// Result Section End

// Visual Effect Section Start

.visual_interface{
    background-color: #FBFAFA;
    .title{
        font-weight: 600;
        font-size: 48.0001px;
        line-height: 57px;
        text-align: center;
        text-transform: capitalize;
        color: #0D0F27;
    }
    .desc{
        font-weight: 500;
        font-size: 18px;
        line-height: 148.84%;
        text-align: center;
        color: #0D0F27;
        width: 45%;
        margin: 15px auto 0 auto;
    }
    .app_tabs{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 23px;
        margin: 40px 0 65px;
        button{
            border-radius: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 14px 28px;
            background-color: #FFFFFF;
            transition: 0.3s all;
            border: 0;
            font-size: 18px;
            font-weight: 600;
            line-height: 24.9px;
            color: #0D0F27;
            height: 91px;
            svg{
                fill: #5D5A5B;
                margin-bottom: 5px;
                transition: 0.3s all;
            }
            &:hover, &.active{
                color: #fff;
                background-color: #1886CD;
                svg{
                    fill: #fff;
                }
            }
        }
    }
    .visual_image{
        width: 100%;
        margin-top: 53px;
        img{
            width: 100%;
        }
    }
}

// Visual Effect Section End

// Technology Section Start

.technologies{
    padding: 120px 0 158px 0;
    background-color: #fff;
    .title{
        font-weight: 600;
        font-size: 48.0001px;
        line-height: 57px;
        text-align: center;
        text-transform: capitalize;
        color: #0D0F27;
    }
    .desc{
        font-weight: 500;
        font-size: 18px;
        line-height: 148.84%;
        text-align: center;
        color: #0D0F27;
        width: 45%;
        margin: 15px auto 0 auto;
    }
    .tech_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 80px;
        .tech_box{
            height: 150px;
            width: 150px;
            border-radius: 50%;
            overflow: hidden;
            margin: 0 25px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

// Technology Section End

// Talk Section Start

.talk{
    padding: 140px 0 92px 0;
    background-color: #FBFAFA;
    .title{
        font-weight: 700;
        font-size: 48.0001px;
        line-height: 57px;
        text-align: left;
        text-transform: capitalize;
        color: #1886CD;
    }
    .desc{
        font-weight: 500;
        font-size: 24px;
        line-height: 170.84%;
        color: #0D0F27;
        margin-top: 15px;
        margin-bottom: 25px;
    }

    .client_wrapper{
        max-height: 761.59px;
        height: 100%;
        padding: 22px;
        border-radius: 20px;
        background-color: #FFFFFF;
        .cli_title{
            width: fit-content;
            flex-wrap: wrap;
            position: relative;
            padding-bottom: 30px;
            &::after{
                content: '';
                height: 30px;
                width: 100%;
                background-image: url('./../../assets/images/Line01.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                position: absolute;
                left: 0;
                bottom: 15px;
            }
            h2{   
                font-weight: 700;
                font-size: 48px;
                line-height: 102px;
                color: #1886CD;
                width: max-content;
            }
        }
    .video_wrapper{
        background-color: #FFFFFF;
        width: 100%;
        position: relative;
        max-height: 498px;
        margin-top: 50px;
        height: 100%;
        .thumbnail{
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: relative;
            border-radius: 13px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .play{
                position: absolute;
                top: 40%;
                left: 50%;
                transform: translate(-50%, -50%);
                button{
                    animation: pulsate1;
                    border: 0;
                    box-shadow: 0px 0px 25px 3px rgba(0, 110, 255, 0.8);
                    background: radial-gradient( rgba(0, 106, 255, 0.8) 60%, rgb(255, 255, 255) 62%);
                    border-radius: 50%;
                    background-color: transparent;
                    &::after {
                        content: "";
                        position: absolute;
                    }
                    &:before {
                        content: "";
                        position: absolute;
                        width: 155%;
                        height: 155%;
                        -webkit-animation-delay: 0s;
                        animation-delay: 0s;
                        animation-duration:0.1s;
                        animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
                        -webkit-animation: pulsate1 0.1s;
                        animation: pulsate1 1s;
                        -webkit-animation-direction: forwards;
                        animation-direction: forwards;
                        -webkit-animation-iteration-count: infinite;
                        animation-iteration-count: infinite;
                        -webkit-animation-timing-function: steps;
                        animation-timing-function: steps;
                        opacity: 1;
                        border-radius: 50%;
                        border: 14px solid rgba(255, 255, 255, .75);
                        top: -28%;
                        left: -28%;
                        background: rgba(0, 0, 0, 0);
                    }
                }
            }
        }
        .video_details{
            padding: 30px 35px;
            background: rgba(24, 134, 205, 0.76);
            border-radius: 13px;
            position: absolute;
            bottom: 0px;
            width: 100%;
            .video_title{
                font-weight: 800;
                font-size: 34.9181px;
                line-height: 35px;
                letter-spacing: -0.2686px;
                color: #FFFFFF;
            }
            .video_dec{
                font-weight: 400;
                font-size: 21.488px;
                line-height: 32px;
                letter-spacing: -0.1343px;
                color: #FFFFFF;
                margin-top: 15px;
            }
        }
    }
}
}

// Talk Section End

@media screen and (max-width: 980px) {
    .talk .client_wrapper .video_wrapper{
        height: unset;
    }
}
/* Mobile Devices */
@media screen and (max-width: 767px) {
    .details_container{
        padding: 100px 0 30px 0;
        .page_links{
            margin-bottom: 20px;
        }
        .content{
            .logo{
                height: 60px;
                width: 60px;
                margin-bottom: 25px;
            }
            .title{
                font-size: 46px;
                margin-bottom: 0px;
                line-height: 46px;
                margin-top: 0px;
            }
            .desc{
                font-size: 18px;
                line-height: 24px;
                width: 100%;
                margin-top: 15px;
                margin-bottom: 30px;
            }
        }
        .detail_image{
            margin-top: 50px;
        }
    }
    .requirement{
        padding: 40px 0;
        .requirement_wrapper{
            padding: 20px;
            .title{
                margin-bottom: 10px;
                font-size: 32px;
            }
            .desc{
                font-size: 18px;
                line-height: 26px;
            }
        }
    }
    .challenges{
        padding: 30px 0;
        .title{
            font-size: 34px;
            margin-bottom: 20px;
        }
        .desc{
            font-size: 18px;
            line-height: 26px;
        }
        .challenge_wrapper{
            padding: 20px;
            margin-bottom: 15px;
            .icon{
                width: 25px;
                height: 25px;
                min-width: 25px;
                margin-right: 15px;
                img{
                    width: 100%;
                }
            }
            .text{
                font-size: 18px;
                line-height: 24px;
            }
        }
    }
    .use_case{
        padding: 0;
        .content{
            .title{
                margin-bottom: 0px;
                font-size: 32px;
            }
            .desc{
                font-size: 18px;
                line-height: 26px;
                margin-top: 0;
            }
            .usecase_box{
                margin-top: 40px;
                .usecase_row{
                    padding: 20px;
                    .icon{
                        width: 25px;
                        height: 25px;
                        min-width: 25px;
                        margin-right: 15px;
                        img{
                            width: 100%;
                        }
                    }
                    .text{
                        font-size: 18px;
                        line-height: 24px;
                    }
                }
            }
        }
        .usecase_image{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 30px;
            img{
                margin: 0;
            }
        }
    }
    .results{
        padding: 60px 0;
        .title{
            margin-bottom: 20px;
            font-size: 34px;
        }
        .result_wrapper{
            padding: 20px;
            height: calc(100% - 15px);
            margin-bottom: 15px;
            .icon{
                width: 25px;
                height: 25px;
                min-width: 25px;
                margin-right: 15px;
                img{
                    width: 100%;
                }
            }
            .text{
                font-size: 18px;
                line-height: 24px;
            }
        }
    }
    .visual_interface{
        .title{
            margin-bottom: 0px;
            font-size: 32px;
        }
        .desc{
            font-size: 18px;
            line-height: 26px;
            margin-top: 0;
            width: 100%;
        }
    }
    .technologies{
        padding: 60px 0;
        .title{
            margin-bottom: 0px;
            font-size: 32px;
        }
        .desc{
            font-size: 18px;
            line-height: 26px;
            margin-top: 0;
            width: 100%;
        }
        .tech_wrapper{
            margin-top: 40px;
            flex-wrap: wrap;
            justify-content: flex-start;
            .tech_box{
                margin : 10px 22px;
            }
        }
    }
    .talk{
        padding: 60px 0;
        .title{
            margin-bottom: 0px;
            font-size: 32px;
        }
        .desc{
            font-size: 18px;
            line-height: 26px;
            margin-top: 0;
            width: 100%;
        }
    }
    .talk {
        .client_wrapper{
            margin-top: 30px;
            .cli_title{
                h2{
                    flex-wrap: wrap;
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 57px;
                }
            }
            .video_wrapper{
                .video_details{
                    padding: 20px;
                .video_title{
                    font-weight: 800;
                    font-size: 20px;
                    line-height: 15px;
                    letter-spacing: -0.2686px;
                    color: #FFFFFF;
                }
                .video_dec{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: -0.1343px;
                    color: #FFFFFF;
                    margin-top: 10px;
                }
            }
        }
        }
    }   
}

/* Tablet Devices */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .details_container{
        padding: 120px 0 30px 0;
        .page_links{
            margin-bottom: 20px;
        }
        .content{
            .logo{
                height: 60px;
                width: 60px;
                margin-bottom: 25px;
            }
            .title{
                font-size: 46px;
                margin-bottom: 0px;
                line-height: 46px;
                margin-top: 0px;
            }
            .desc{
                font-size: 18px;
                line-height: 24px;
                width: 100%;
                margin-top: 15px;
                margin-bottom: 30px;
            }
        }
        .detail_image{
            margin-top: 50px;
        }
    }
    .requirement{
        padding: 40px 0;
        .requirement_wrapper{
            padding: 20px;
            .title{
                margin-bottom: 10px;
                font-size: 32px;
            }
            .desc{
                font-size: 20px;
                letter-spacing: 0.5px;
                font-weight: 400;
                line-height: 26px;
            }
        }
    }
    .use_case{
        padding: 30px 0;
        .content{
            .usecase_box{
                margin-top: 40px;
                margin-bottom: 50px;
                .usecase_row{
                    padding: 20px;
                    .icon{
                        width: 25px;
                        height: 25px;
                        min-width: 25px;
                        margin-right: 15px;
                        img{
                            width: 100%;
                        }
                    }
                    .text{
                        font-size: 20px;
                        line-height: 30px;
                        letter-spacing: 0.5px;
                    }
                }
            }
        }
        .usecase_image{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 0px;
            img{
                margin: 0;
            }
        }
    }
    .results{
        padding: 60px 0;
    }
    .visual_interface{
        .desc{
            width: 100%;
        }
        .visual_image{
            margin-top: 30px;
        }
    }
    .technologies{
        padding: 60px 0;
        .desc{
            width: 100%;
        }
        .tech_wrapper{
            margin-top: 40px;
            flex-wrap: wrap;
            justify-content: flex-start;
            .tech_box{
                margin : 10px;
            }
        }
    }
    .talk{
        padding: 120px 0;
        .client_wrapper{
            margin-top: 20px;
            .cli_title{
               padding: 20px 0px;
               margin: 0;
            }
        }
    }
}

/* Laptop Devices */
@media screen and (min-width: 1024px) and (max-width: 1365px) {
    .details_container{
        padding: 120px 0 30px 0;
        .page_links{
            margin-bottom: 20px;
        }
        .content{
            .logo{
                height: 60px;
                width: 60px;
                margin-bottom: 25px;
            }
            .title{
                font-size: 46px;
                margin-bottom: 0px;
                line-height: 46px;
                margin-top: 0px;
            }
            .desc{
                font-size: 18px;
                line-height: 24px;
                width: 100%;
                margin-top: 15px;
                margin-bottom: 30px;
            }
        }
        .detail_image{
            margin-top: 50px;
        }
    }
    // .talk{
    //     .cli_title{
    //     //    margin-bottom: 116px;
    //     }
    // }

    .requirement{
        padding: 40px 0;
        .requirement_wrapper{
            padding: 20px;
            .title{
                margin-bottom: 10px;
                font-size: 32px;
            }
            .desc{
                font-size: 20px;
                letter-spacing: 0.5px;
                font-weight: 400;
                line-height: 26px;
            }
        }
    }
    .use_case{
        padding: 30px 0;
        .content{
            .usecase_box{
                margin-top: 40px;
                margin-bottom: 50px;
                .usecase_row{
                    padding: 20px;
                    .icon{
                        width: 25px;
                        height: 25px;
                        min-width: 25px;
                        margin-right: 15px;
                        img{
                            width: 100%;
                        }
                    }
                    .text{
                        font-size: 20px;
                        line-height: 30px;
                        letter-spacing: 0.5px;
                    }
                }
            }
        }
        .usecase_image{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 40px;
            img{
                margin: 0;
                width: 100%;
                max-width: 450px;
            }
        }
    }
    .results{
        padding: 60px 0;
    }
    .visual_interface{
        .desc{
            width: 70%;
        }
        .visual_image{
            margin-top: 30px;
        }
    }
    .technologies{
        padding: 60px 0;
        .desc{
            width: 70%;
        }
        .tech_wrapper{
            margin-top: 40px;
            flex-wrap: wrap;
            justify-content: center;
            .tech_box{
                margin : 10px;
            }
        }
    }
    .talk{
        padding: 60px 0;
    }
}

/* Desktop Devices */
@media screen and (min-width: 1366px) and (max-width: 1919px) {}

/* TV Devices */
@media screen and (min-width: 1920px) {}