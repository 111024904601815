.map{
    height: 656px;
    width: 100%;
    iframe{
        width: 100%;
        height: 100%;
    }
}

/* Tablet Devices */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .map{
        height: 500px;
    }
}

/* Laptop Devices */
@media screen and (min-width: 1024px) and (max-width: 1365px) {
    .map{
        height: 500px;
    }
}

/* Desktop Devices */
@media screen and (min-width: 1366px) and (max-width: 1919px) {}

/* TV Devices */
@media screen and (min-width: 1920px) {}