.desc_section{
    padding: 72px 0;
    background-color: #fff;
    font-size: 50px;
    font-weight: 700;
    line-height: 69px;
    letter-spacing: 0px;
    text-align: center;
}

.technologies{
    background-color: #F4F4F4;
    padding: 130px 0;
    &.white{
        background-color: #fff;
        .techno_box{
            background-color: #F4F4F4;
        }
    }
    .heading{
        text-align: left;
        .title{
            text-align: left;
            font-size: 56px;
            font-weight: 700;
            line-height: 67px;
            letter-spacing: 0px;
            color: #1886CD;
        }
        .desc{
            text-align: left;
            width: 100%;
            font-size: 28px;
            font-weight: 800;
            line-height: 36px;
            letter-spacing: 0px;
            margin-bottom: 50px;
            
        }
    }
    .techno_box{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: #fff;
        padding: 50px 43px;
        border-radius: 18px;
        overflow: hidden;
        margin-top: 30px;
        .tech_icon{
            height: 90px;
            width: 90px;
            min-width: 90px;
            margin-right: 40px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .tech_name{
            font-size: 26px;
            font-weight: 700;
            line-height: 48px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
        }
    }
}



@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .technologies .techno_box{
        padding: 30px 25px;
        .tech_icon{
            margin-right: 20px;
        }
    }
}

@media screen and (max-width: 767px) {
    .desc_section{
        padding: 30px 0;
        font-size: 24px;
        line-height: 34px;
        word-wrap: break-word;
    }
    .technologies{
        padding: 50px 0;
        .heading{
            .title{
                font-size: 26px;
                line-height: 34px;
            }
            .desc{
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
                margin-top: 10px;
                margin-bottom: 0;
            }
        }
        .techno_box{
            padding: 15px 15px;
            .tech_icon {
                height: 50px;
                width: 50px;
                min-width: 50px;
                margin-right: 20px;
            }
            .tech_name{
                font-size: 18px;
                line-height: 28px;
            }
        }
    }
}