// Hero Section
.widgets_wrapper {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.hero {
  min-height: 100vh;
  // background-image: url('./../../assets/images/dots.png');
  // position: relative;
  // background-size: cover;
  // background-position:center;
  // background-repeat: no-repeat;
  padding: 130px 0 15px 0;
  .back {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
  }
  // &::after{
  //     content: '';
  //     background: #F51709;
  //     opacity: 0.3;
  //     filter: blur(250px);
  //     position: absolute;
  //     width: 500px;
  //     height: 500px;
  //     left: -250px;
  //     top: 140px;
  // }
  // &::before{
  //     content: "";
  //     background: #1886CD;
  //     opacity: 0.5;
  //     filter: blur(250px);
  //     position: absolute;
  //     width: 500px;
  //     height: 500px;
  //     right: -0px;
  //     top: -50px;
  // }
  .hero_wrapper {
    // &::after{
    //     content: "";
    //     background: #1886CD;
    //     opacity: 0.5;
    //     filter: blur(200px);
    //     position: absolute;
    //     width: 400px;
    //     height: 400px;
    //     left: 100px;
    //     top: 140px;
    // }
    .content {
      height: calc(100vh - 150px);
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      .title {
        position: relative;
        padding-bottom: 30px;
        &::after {
          content: "";
          height: 30px;
          width: 100%;
          background-image: url("./../../assets/images/Line01.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          position: absolute;
          left: 0;
          bottom: 5px;
        }
        h2 {
          font-family: Helvetica;
          font-weight: 700;
          font-size: 92.1979px;
          line-height: 109px;
          text-transform: uppercase;
          color: #1886cd;
          width: max-content;
          span {
            color: #0d0f27;
            text-transform: capitalize;
            display: block;
          }
        }
      }
      .sub_title {
        position: relative;
        h3 {
          font-family: Helvetica;
          font-weight: 700;
          font-size: 71.7095px;
          line-height: 110px;
          color: #0d0f27;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          span {
            font-weight: 700;
            font-size: 51.2211px;
            line-height: 29px;
            display: block;
          }
        }
      }
      .desc {
        span {
          font-weight: 700;
          font-size: 24px;
          line-height: 31px;
          color: #0d0f27;
          span {
            display: block;
          }
        }
      }
      .hero_action {
        margin-top: 30px;
        a {
          font-family: roboto;
          padding: 10px 25px;
          border-radius: 10px;
          background: #ffffff;
          border: 1px solid #1886cd;
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          text-align: center;
          color: #1886cd;
          text-decoration: none;
          outline: none;
          &::after {
            opacity: 0;
            display: none;
          }
          &:nth-child(2) {
            background-color: #1886cd;
            color: #fff;
            margin-left: 15px;
          }
        }
      }
      .clutch_icon {
        margin-top: 30px;
        height: 80px;
        .clutch {
          display: block;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .hero_graphic {
      height: calc(100vh - 150px);
      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex-direction: column;
      position: relative;
      .graphic_img_wrapper {
        max-width: 774px;
        max-height: 500px;
        width: 100%;
        height: 100%;
        position: relative;
        .owl-dots {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          margin-bottom: 10px;
          .owl-dot {
            background-color: #fff;
            height: 16px;
            width: 16px;
            margin: 0 5px;
            border-radius: 20px;
            position: relative;
            z-index: 1;
            &::after {
              content: "";
              height: 20px;
              width: 20px;
              background-color: #fff;
              border: 1px solid #1886cd;
              position: absolute;
              top: -2px;
              left: -2px;
              border-radius: 50%;
              z-index: 0;
              opacity: 0;
              transition: 0.3s;
            }
            &::before {
              content: "";
              height: 14px;
              width: 14px;
              background-color: #1886cd;
              position: absolute;
              top: 1px;
              left: 1px;
              border-radius: 50%;
              z-index: 1;
              opacity: 0;
              transition: 0.3s;
            }
            &.active {
              background: #fff;
              &::after,
              &::before {
                opacity: 1;
              }
            }
          }
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
          position: relative;
          z-index: 1;
        }
        &::after {
          content: "";
          height: 90%;
          width: 90%;
          position: absolute;
          bottom: 5px;
          right: -12px;
          z-index: 0;
          background: #1886cd;
          border-radius: 10px;
        }
      }
    }
  }
}

// Why Choose Section Start

.why_choose {
  padding: 150px 0;
  background-color: #fdfdfd;
  .wc_title {
    h2 {
      font-weight: 800;
      font-size: 56px;
      line-height: 67px;
      text-transform: capitalize;
      color: #1886cd;
      margin: 15px 0;
    }
    p {
      font-weight: 800;
      font-size: 30px;
      line-height: 138.34%;
      color: #0c0f31;
      span {
        color: #1886cd;
      }
    }
  }
  .wc_box {
    background-color: #fff;
    padding: 30px 20px;
    box-shadow: 0px 3.71171px 46.3964px #00000005;
    border-radius: 14px;
    .count {
      font-weight: 800;
      font-size: 45px;
      line-height: 54px;
      text-align: center;
      text-transform: capitalize;
      color: #1886cd;
      padding-bottom: 18px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .text_icon {
        color: #5d5a5b;
        font-size: 40px;
        display: block;
        margin-left: 2px;
      }
      &::after {
        content: "";
        left: 50%;
        bottom: 5px;
        width: 60px;
        height: 1px;
        background-color: #1886cd;
        position: absolute;
        z-index: 1;
        border-radius: 8px;
        transform: translateX(-50%);
      }
    }
    .count_text {
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      text-transform: capitalize;
      color: #0d0f27;
      padding: 6px 15px 0;
    }
  }
}

// Why Choose Section End

// what we belive start
.what_we_believe {
  background-color: #fff;
  padding: 100px 0;
  text-align: center;
  .heading {
    h2 {
      font-weight: 700;
      font-size: 56px;
      line-height: 67px;
      color: #1886cd;
    }
    p {
      font-weight: 500;
      font-size: 39px;
      line-height: 80px;
      color: #0d0f27;
      span {
        color: #1886cd;
        position: relative;
        text-transform: capitalize;
        // text-decoration: underline;
        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: #1886cd;
          left: 0;
          bottom: -10px;
        }
      }
    }
  }
}
// what we belive end

// Industries Worked For Start
.industries_worked{
  padding: 120px 0 0px;
  .ind_title{
    font-size: 56px;
    line-height: 66px;
    font-weight: 800;
    color: #1886CD;
    margin-bottom: 94px;
  }
  .ind_type{
    font-size: 48px;
    line-height: 60px;
    font-weight: 800;
    text-transform: uppercase;
    color: #0D0F27;
    margin-bottom: 20px;
  }
  .ind_box{
    height: 337px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 18px;
    padding: 50px 40px;
    margin-bottom: 24px;
    &.big{
      padding: 76px 58px;
      height: 600px;
      .name{
        font-size: 40px;
        font-weight: 800;
        line-height: 1.3;
      }
    }
    .name{
      font-size: 36px;
      font-weight: 800;
      line-height: 1.3;
      color: #0D0F27;
    }
  }
}
// Industries Worked For End

// aspiring industries start
.aspiring_industries {
  padding: 70px 0;
  h2.heading {
    text-align: center;
    font-weight: 700;
    font-size: 56px;
    line-height: 67px;
    color: #1886cd;
    margin-bottom: 50px;
  }
  .aspiring_industries_card {
    width: 100%;
    aspect-ratio: 19/20;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
    .thumbnail {
      width: 100%;
      height: 100%;
      position: relative;
      &::after {
        height: 100%;
        display: block;
        content: "";
        background: linear-gradient(to top, #100E0E70 20%, #00000000 10%);
        inset: 0;
        width: 100%;
        position: absolute;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      p {
        margin: 0 0 20px 40px;
        color: white;
        font-size: 28px;
        letter-spacing: 1px;
        position: absolute;
        bottom: 0;
        z-index: 1;
      }
    }
    .content {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 100%;
      background: #1886cd;
      color: #ffffff;
      transition: all 0.3s ease-in-out;
      display: flex;
      flex-direction: column;
      padding: 30px 40px;
      z-index: 2;
      img {
        margin-left: auto;
        margin-top: -10px;
        margin-right: -20px;    
      }
      h6 {
        margin-top: auto;
        font-size: 28px;
        line-height: 28px;
      }
      p {
        margin: 30px 0;
        font-size: 20px;
        line-height: 26px;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;  
        overflow: hidden;
      }
      div {
        gap: 18px;
        button {
          background: #ffffff;
          color: #1886cd;
          border: none;
          padding: 8px 10px;
          border-radius: 5px;
          font-weight: 500;
          font-size: 20px;
          line-height: normal;
        }
      }
    }
    &:hover {
      .content {
        top: 0;
      }
    }
  }
  .aspiring_industries_view_all {
    border: none;
    display: flex;
    align-items: center;
    gap: 25px;
    font-size: 28px;
    font-weight: 500;
    color: #1886cd;
    transition: all 0.3s ease-in-out;
    background-color: transparent;
    &:hover {
      gap: 30px;
    }
  }
}
// aspiring industries end

// What Can We Do Section Start

.what_cw_do {
  overflow: hidden;
  padding: 60px 0;
  //   background-color: #fff;
  position: relative;
  &:after {
    content: "";
    background: #f51709;
    opacity: 0.3;
    filter: blur(250px);
    position: absolute;
    width: 500px;
    height: 500px;
    left: -250px;
    top: 400px;
    z-index: -1;
  }
  &:before {
    content: "";
    background: #1886cd;
    opacity: 0.5;
    filter: blur(250px);
    position: absolute;
    width: 250px;
    height: 250px;
    right: 0px;
    top: 800px;
    z-index: -1;
  }
  .heading {
    h2 {
      font-weight: 700;
      font-size: 56px;
      line-height: 67px;
      color: #1886cd;
    }
    p {
      font-weight: 800;
      font-size: 30px;
      line-height: 36px;
      text-transform: capitalize;
      color: #0d0f27;
    }
    .big {
      padding-top: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .title_span {
        transition: 0.6s ease-in-out;
        position: relative;
        display: block;
        font-weight: 800;
        font-size: 98.7356px;
        line-height: 118px;
        text-transform: uppercase;
        color: #0d0f27;
        padding-right: 3px;
        &:nth-child(2) {
          color: #1886cd;
        }
      }
      .big_animation1 {
        transition: 0.6s ease-in-out;
        animation: title_animation1 1s ease-out 0.1s 1 backwards;
        position: relative;
        display: block;
        font-weight: 800;
        font-size: 98.7356px;
        line-height: 118px;
        text-transform: uppercase;
        color: #0d0f27;
        padding-right: 3px;
        &:nth-child(2) {
          color: #1886cd;
        }
      }
      .big_animation2 {
        transition: 0.6s ease-in-out;
        animation: title_animation2 1s ease-out 0.1s 1 backwards;
        position: relative;
        display: block;
        font-weight: 800;
        font-size: 98.7356px;
        line-height: 118px;
        text-transform: uppercase;
        color: #0d0f27;
        padding-right: 3px;
        &:nth-child(2) {
          color: #1886cd;
        }
      }
      .big_animation3 {
        animation: title_animation3 1s ease-out 0.1s 1 backwards;
        transition: 0.6s ease-in-out;
        position: relative;
        display: block;
        font-weight: 800;
        font-size: 98.7356px;
        line-height: 118px;
        text-transform: uppercase;
        color: #0d0f27;
        padding-right: 3px;
        &:nth-child(2) {
          color: #1886cd;
        }
      }
    }
  }
  .service_box {
    padding: 40px;
    width: 100%;
    border-radius: 18px;
    margin-top: 75px;
    &.box1 {
      background-color: #fef6cd;
      .title {
        &::after {
          background-color: #efc800;
        }
      }
    }
    &.box2 {
      background-color: #fedeef;
      .title {
        &::after {
          background-color: #f859ae;
        }
      }
    }
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      &::after {
        content: "";
        left: 0;
        bottom: -20px;
        width: 135px;
        height: 1px;
        background-color: #efc800;
        position: absolute;
        z-index: 1;
        border-radius: 8px;
      }
      .text {
        font-weight: 800;
        font-size: 35px;
        line-height: 32px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #0d0f27;
      }
    }
    .services {
      margin-top: 100px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .service_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 10px;
        .icon {
          width: 90px;
          height: 90px;
          border: 5px solid #fff;
          border-radius: 50%;
          margin-bottom: 20px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            background-position: center;
            height: 100%;
            width: 100%;
          }
        }
        .text {
          font-weight: 700;
          font-size: 22px;
          line-height: 32px;
          text-align: center;
          letter-spacing: 0.5px;
          color: #000000;
          width: 92%;
        }
      }
    }
  }
  .service_card {
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 18px;
    padding: 44px 36px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    img {
      width: fit-content;
      height: 95px;
    }
    span {
      font-weight: 700;
      color: #0d0f27;
      font-size: 24px;
      letter-spacing: 0.5px;
      line-height: 34px;
      margin: 20px 0;
    }
    p {
      color: #0d0f27;
      letter-spacing: 0.5px;
      line-height: 30px;
      margin: auto 0 0;
    }
  }
  div:nth-child(1) > .service_card:hover {
    background-color: #fffae3;
    border: 1px solid #ffd500;
  }
  div:nth-child(2) > .service_card:hover {
    background-color: #eaf7ff;
    border: 1px solid #1886cd;
  }
  div:nth-child(3) > .service_card:hover {
    background-color: #edfff3;
    border: 1px solid #29cc5e;
  }
  div:nth-child(4) > .service_card:hover {
    background-color: #ffe7e2;
    border: 1px solid #d93f21;
  }
  @keyframes title_animation1 {
    0% {
      opacity: 0;
      left: -100%;
    }
    50% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
      left: 0px;
    }
  }
  @keyframes title_animation2 {
    0% {
      opacity: 0;
      left: 0%;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      left: 0px;
    }
  }
  @keyframes title_animation3 {
    0% {
      opacity: 0;
      right: -100%;
    }
    50% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
      right: 0px;
    }
  }

  // @keyframes box1_animation {
  //     0%{
  //         opacity: 0;
  //         left:-100%;
  //     }
  //     50%{
  //         opacity: 0.6;
  //     }
  //     100%{
  //         opacity: 1;
  //         left: 0px;
  //     }
  //  }
  //  @keyframes box2_animation {
  //     0%{
  //         opacity: 0;
  //         right:-100%;
  //     }
  //     50%{
  //         opacity: 0.6;
  //     }
  //     100%{
  //         opacity: 1;
  //         right: 0px;
  //     }
  //  }

  .view_all {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 80px 0 30px 0;
    button {
      border: 0;
      background-color: transparent;
      outline: none;
      font-weight: 600;
      font-size: 25px;
      line-height: 30px;
      color: #1886cd;
      text-align: center;
      img {
        margin-left: 10px;
      }
    }
  }
}

// What Can We Do Section End

// Case studies section start

.case_studies {
  padding: 100px 0;
  position: relative;
  // &::after {
  //   content: "";
  //   background: #f51709;
  //   opacity: 0.3;
  //   filter: blur(250px);
  //   position: absolute;
  //   width: 500px;
  //   height: 500px;
  //   left: -250px;
  //   top: 100px;
  //   z-index: -1;
  // }
  // &::before {
  //   content: "";
  //   background: #1886cd;
  //   opacity: 0.5;
  //   filter: blur(250px);
  //   position: absolute;
  //   width: 250px;
  //   height: 250px;
  //   right: -0px;
  //   top: -0px;
  //   z-index: -1;
  // }
  .owl-item {
    // margin: auto !important;
    transition: 0.3s;
    transform: scale(0.9);
    &.center {
      margin: 0 !important;
      transform: scale(1);
    }
  }
  .heading {
    width: 100%;
    text-align: center;
    margin-bottom: 100px;
  }
  .heading .title {
    font-weight: 700;
    font-size: 56px;
    line-height: 67px;
    text-align: center;
    text-transform: capitalize;
    color: #1886cd;
  }
  .heading .des {
    font-weight: 800;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    color: #0d0f27;
    width: 72%;
    margin: 0 auto;
    margin-top: 10px;
  }
  .owl-stage {
    display: flex;
    .owl-item {
      &:nth-child(1) {
        .case_box {
          background-color: #7B7C3E;
        }
      }
      &:nth-child(2) {
        .case_box {
          background-color: #6950BA;
        }
      }
      &:nth-child(3) {
        .case_box {
          background-color: #cea433;
        }
      }
      &:nth-child(4) {
        .case_box {
          background-color: #1f9ba3;
        }
      }&:nth-child(5) {
        .case_box {
          background-color: #1886cd;
        }
      }
    }
  }
  a {
    text-decoration: none;
    display: block;
    height: 100%;
    .case_box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 80px 50px 30px 80px;
      width: 100%;
      height: 100%;
      border-radius: 30px;
      overflow: hidden;
      position: relative;
      &::after {
        content: "";
        background: #fff;
        opacity: 1;
        position: absolute;
        width: 30%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 0;
      }
      .content {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;
        .image {
          max-width: 410px;
          img {
            width: 100%;
          }
        }
        .links {
          padding-left: 50px;
          .text {
            margin-bottom: 20px;
            font-weight: 700;
            font-size: 48px;
            line-height: 58px;
            color: #fff;
          }
          .tag_line{
            font-size: 16px;
            font-weight: 500;
            line-height: 18px;
            color: #fff;
            max-width: 200px;
            margin-bottom: 15px;
          }
          .store {
            button {
              background-color: transparent;
              border: 0px;
              margin-right: 20px;
            }
          }
          .industries {
            color: #fff;
            margin-top: 5px;
            display: flex;
            flex-wrap: wrap;
            span {
              display: flex;
              object-fit: contain;
              display: block;
              border-radius: 18.5px;
              padding: 5px 15px;
              margin: 0 6px;
              color: black;
              background: #e1e1e1ff;
              font-size: 16px;
              font-weight: 500;
              line-height: 19px;
              letter-spacing: 0px;
              margin-top: 5px;
            }
          }
        }
      }
      .action {
        margin-top: 30px;
        margin-right: 30px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        a {
          text-decoration: none;
          background-color: transparent;
          border: 0px;
          font-weight: 600;
          font-size: 25px;
          line-height: 30px;
          text-align: center;
          letter-spacing: -0.8px;
          color: #ffffff;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          img {
            width: 49px;
            margin-left: 30px;
          }
        }
      }
    }
  }
  .view_all {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 0 30px 0;
    a {
      border: 0;
      background-color: transparent;
      outline: none;
      font-weight: 600;
      font-size: 25px;
      line-height: 30px;
      text-align: center;
      color: #1886cd;
      text-decoration: none;
      img {
        margin-left: 10px;
      }
    }
  }
}

// Case studies section end

// Client speak section start

.client_speak {
  padding-top: 100px;
  padding-bottom: 150px;
  .owl-stage {
    padding: 30px 0px;
  }
  .owl-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: -146px;
    right: 0px;
    .owl-prev,
    .owl-next {
      svg {
        fill: #1886cd;
      }
    }
    .owl-prev,
    .owl-next {
      &.disabled {
        svg {
          fill: #5d5a5b;
        }
      }
    }
    button {
      margin-left: 10px;
      border: 0;
      background-color: transparent;
      border-radius: 50px;
    }
  }
  .heading {
    margin-bottom: 100px;
    h2 {
      font-weight: 700;
      font-size: 56px;
      line-height: 67px;
      color: #1886cd;
    }

    .desc {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-weight: 800;
        font-size: 30px;
        line-height: 36px;
        text-transform: capitalize;
        color: #0d0f27;
      }
      .slide_action {
        position: absolute;
        top: 50%;
        button {
          border: 0;
          background-color: transparent;
          outline: none;
          margin-left: 10px;
          height: 35px;
          width: 35px;
          img {
            width: 100%;
          }
        }
      }
    }
  }

  //   .crousel_track {
  //     padding-left: 12px;
  //     margin-left: 2%;
  // }
  .client_carousel {
  }
  .slick-track {
    display: flex;
    margin-left: 2%;
  }
  .slick-prev,
  .slick-next {
    top: -15%;
    fill: #1886cd;
  }
  .slick-prev::before,
  .slick-next::before {
    content: '';
  }
  .slick-prev {
    left: unset;
    right: 200px;
  }
  .slick-next {
    right: 135px;
  }
  .slick-prev.slick-disabled svg,
  .slick-next.slick-disabled svg {
    fill: #0d0f27;
  }
  .client_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
  }
  .slide_action {
    button {
      border: 0;
      background-color: transparent;
      outline: none;
      height: 35px;
      width: 35px;
      margin: 0 10px;
    }
  }
  // .client_box {
  //   background: #ffffff;
  //   border-radius: 20px;
  //   padding: 23px;
  //   width: 100%;
  //   margin-right: 30px;
  //   .client_img {
  //     overflow-y: hidden;
  //     max-width: 100%;
  //     max-height: 265px;
  //     height: 100%;
  //     width: 100%;
  //     border-radius: 13px;
  //     position: relative;
  //     img {
  //       height: 100%;
  //       border-radius: 13px;
  //       width: 100%;
  //       object-fit: cover;
  //     }
  //   }
  //   .content {
  //     display: flex;
  //     justify-content: flex-start;
  //     align-items: center;
  //     margin-top: 30px;
  //     margin-bottom: 0px;
  //     .button {
  //       animation-name: pulsate1;
  //       position: absolute;
  //       right: 45px;
  //       bottom: 97px;
  //       box-shadow: 0px 0px 25px 3px rgba(0, 110, 255, 0.8);
  //       background: radial-gradient(
  //         rgba(0, 106, 255, 0.8) 60%,
  //         rgb(255, 255, 255) 62%
  //       );
  //       border-radius: 50%;
  //       &::after {
  //         content: "";
  //         position: absolute;
  //       }
  //       &:before {
  //         content: "";
  //         position: absolute;
  //         width: 155%;
  //         height: 155%;
  //         -webkit-animation-delay: 0s;
  //         animation-delay: 0s;
  //         animation-duration: 0.1s;
  //         animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  //         -webkit-animation: pulsate1 0.1s;
  //         animation: pulsate1 1s;
  //         -webkit-animation-direction: forwards;
  //         animation-direction: forwards;
  //         -webkit-animation-iteration-count: infinite;
  //         animation-iteration-count: infinite;
  //         -webkit-animation-timing-function: steps;
  //         animation-timing-function: steps;
  //         opacity: 1;
  //         border-radius: 50%;
  //         border: 14px solid rgba(255, 255, 255, 0.75);
  //         top: -28%;
  //         left: -28%;
  //         background: rgba(0, 0, 0, 0);
  //       }
  //     }
  //     .avtar {
  //       height: 75px;
  //       width: 75px;
  //       border-radius: 50%;
  //       overflow: hidden;
  //       margin-right: 20px;
  //       img {
  //         height: 100%;
  //         width: 100%;
  //         object-fit: cover;
  //       }
  //     }
  //     .details {
  //       .name {
  //         font-weight: 800;
  //         font-size: 23.0829px;
  //         line-height: 24px;
  //         letter-spacing: -0.177561px;
  //         color: #0c0f31;
  //       }
  //       .address {
  //         font-weight: 400;
  //         font-size: 16.2049px;
  //         line-height: 22px;
  //         color: #0c0f31;
  //         padding-top: 2px;
  //         display: flex;
  //         img {
  //           height: 20px;
  //           width: 20px;
  //           margin: 0px 5px;
  //         }
  //       }
  //     }
  //   }
  //   transition: 0.6s linear;
  // }
  // .client_box:hover {
  //   transform: scale(1.1);
  //   box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  // }
  .client_box {
    background: #ffffff;
    border-radius: 10px;
    padding: 0;
    width: 100%;
    margin-right: 30px;
    max-width: 450px;
    position: relative;
    .client_img {
      overflow: hidden;
      max-width: 100%;
      height: 500px;
      width: 100%;
      border-radius: 10px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 100%;
        border-radius: 10px;
        width: unset;
        object-fit: cover;
      }
    }
    .content {
      position: absolute;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 0px;
      padding: 32px 30px;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 0 0 10px 10px;
      // background-image: linear-gradient(to top right, #ffffff, rgba(255,0,0,0) );
      background: linear-gradient(
        360deg,
        #36454c 0%,
        rgba(255, 255, 255, 0) 100%
      );

      .button {
        animation-name: pulsate1;
        position: absolute;
        right: 30px;
        bottom: 25px;
        box-shadow: 0px 0px 25px 3px rgba(0, 110, 255, 0.8);
        background: radial-gradient(
          rgba(0, 106, 255, 0.8) 60%,
          rgb(255, 255, 255) 62%
        );
        border-radius: 50%;
        &::after {
          content: "";
          position: absolute;
        }
        &:before {
          content: "";
          position: absolute;
          width: 155%;
          height: 155%;
          -webkit-animation-delay: 0s;
          animation-delay: 0s;
          animation-duration: 0.1s;
          animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
          -webkit-animation: pulsate1 0.1s;
          animation: pulsate1 1s;
          -webkit-animation-direction: forwards;
          animation-direction: forwards;
          -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
          -webkit-animation-timing-function: steps;
          animation-timing-function: steps;
          opacity: 1;
          border-radius: 50%;
          border: 14px solid rgba(255, 255, 255, 0.75);
          top: -28%;
          left: -28%;
          background: rgba(0, 0, 0, 0);
        }
      }
      .avtar {
        height: 75px;
        width: 75px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 20px;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .details {
        .name {
          font-weight: 800;
          font-size: 23.0829px;
          line-height: 24px;
          letter-spacing: -0.177561px;
          color: #fff;
        }
        .address {
          font-weight: 400;
          font-size: 16.2049px;
          line-height: 22px;
          color: #fff;
          padding-top: 2px;
          display: flex;
          img {
            height: 20px;
            width: 20px;
            margin: 0px 5px;
          }
        }
      }
    }
    transition: 0.6s linear;
  }
  .client_box:hover {
    transform: scale(1.1);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
}

@keyframes pulsate1 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(101, 101, 101, 0.75),
      0px 0px 25px 10px rgba(43, 43, 43, 0.75);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.4;
    box-shadow: none;
  }
  75% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0.2;
    box-shadow: none;
  }

  100% {
    -webkit-transform: scale(1.4);
    transform: scale(1.3);
    opacity: 0;
    box-shadow: none;
  }
}

// Client speak section end

// Our Clients section start

.clients {
  padding-bottom: 120px;
  .heading {
    width: 100%;
    text-align: center;
    margin-bottom: 100px;
  }
  .heading .title {
    font-weight: 700;
    font-size: 56px;
    line-height: 67px;
    text-align: left;
    text-transform: capitalize;
    color: #1886cd;
  }
  .heading .des {
    font-weight: 800;
    font-size: 30px;
    line-height: 40px;
    text-align: left;
    color: #0d0f27;
    width: 100%;
    margin: 0 0;
    margin-top: 10px;
  }
  .client_wrapper {
    // width: 100%;
    // display: flex;
    // justify-content: space-between;
    // flex-wrap: wrap;
    // padding: 0 0px;
    .client_logo {
      // transition: 0.6s linear;
      max-height: 181px;
      max-width: 181px;
      height: max-content;
      width: max-content;
      border-radius: 00px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      padding: 12px;
      mix-blend-mode: multiply;

      // &:nth-child(2) {
      //   height: 120px;
      //   width: 120px;
      //   margin-left: 94px;
      //   margin-top: 21px;
      // }
      // &:nth-child(3) {
      //   height: 174px;
      //   width: 174px;
      //   margin-left: 47px;
      //   margin-top: 21px;
      // }
      // &:nth-child(4) {
      //   height: 144px;
      //   width: 144px;
      //   margin-left: 31px;
      // }
      // &:nth-child(5) {
      //   height: 144px;
      //   width: 144px;
      //   margin-top: 21px;
      //   margin-left: 31px;
      // }
      // &:nth-child(6) {
      //   height: 114px;
      //   width: 114px;
      //   margin-left: 31px;
      //   margin-top: 31px;
      // }
      // &:nth-child(7) {
      //   height: 166px;
      //   width: 166px;
      //   margin-top: 31px;
      // }
      // &:nth-child(8) {
      //   height: 115px;
      //   width: 115px;
      //   margin-top: -11px;
      // }
      // &:nth-child(9) {
      //   height: 144px;
      //   width: 144px;
      //   margin-top: 41px;
      // }
      // &:nth-child(10) {
      //   height: 144px;
      //   width: 144px;
      //   margin-top: -11px;
      // }
      // &:nth-child(11) {
      //   height: 102px;
      //   width: 102px;
      // }
      img {
        // height: calc(100% + 20px);
        // width: calc(100% + 20px);
        width: 100%;
        object-fit: cover;
        filter: saturate(0);
        &:hover {
          filter: saturate(1);
          cursor: pointer;
        }
      }
    }
    // .client_logo:hover{
    //     transform: scale(1.2);
    //     box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    // }
  }

  .client_desc {
    width: 100%;
    margin: 0 0;
    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 143.84%;
      text-align: left;
      text-transform: capitalize;
      color: #0d0f27;
    }
  }
  .view_all {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0 30px 0;
    a {
      border: 0;
      background-color: transparent;
      outline: none;
      font-weight: 600;
      font-size: 25px;
      line-height: 30px;
      text-align: center;
      color: #1886cd;
      text-decoration: none;
      img {
        margin-left: 10px;
      }
    }
  }
}
// Our Clients section end

// Content Section Start

.home_contect {
  background: #ffffff80;
  padding: 124px 0 142px 0;
}

// Content Section end

/* Mobile Devices */
@media screen and (max-width: 320px) {
  .hero .hero_wrapper .content {
    .sub_title {
      h3 {
        font-size: 30px;
        span {
          font-size: 20px;
        }
      }
    }
    .hero_action {
      flex-wrap: wrap;
      a {
        font-size: 12px !important;
        padding: 6px 10px !important;
        border-radius: 5px;
        &:last-child {
          margin-left: 5px;
        }
      }
    }
    .content .links .store {
      button {
        background-color: transparent;
        border: 0px;
        margin-right: 8px;
        img {
          height: 40px;
          width: 40px;
        }
      }
    }

    .client_speak .client_wrapper .client_box .content .button {
      bottom: 82px;
      width: 51px;
    }
  }
}

@media screen and (max-width: 575px) {
  .case_studies {
    padding: 50px 0;
    .heading {
      margin-bottom: 50px;
      .title {
        font-size: 46px;
        margin-bottom: 0;
      }
      .des {
        font-size: 18px;
        line-height: 24px;
        width: 100%;
      }
    }
    .owl-item {
      &.center {
        transform: scale(0.9);
      }
    }
    .case_box {
      padding: 30px 20px !important;
      margin: 0 auto;
      border-radius: 15px;
      justify-content: space-between !important;
      &::after {
        width: 100% !important;
        height: 35% !important;
      }
      .content {
        flex-direction: column;
        .image {
          max-width: 180px;
        }
        .links {
          padding: 40px 0 10px !important;
          .text {
            font-size: 26px;
            line-height: 38px;
          }
          .store {
            button {
              background-color: transparent;
              border: 0px;
              margin-right: 8px;
            }
          }
        }
      }
      .action {
        margin-top: 10px;
        justify-content: flex-end;
        padding: 10px 0 0px 0;
        a {
          font-size: 18px;
          line-height: 24px;
          img {
            margin-left: 20px;
            width: 30px;
          }
        }
      }
    }
    .view_all {
      margin-top: 10px;
      justify-content: center;
      padding: 10px 0 0px 0;
      a {
        font-size: 18px;
        line-height: 24px;
        img {
          margin-left: 20px;
          width: 30px;
        }
      }
    }
  }
} /* Tablet Devices */
@media screen and (max-width: 767px) {
  .hero {
    .hero_wrapper {
      .content {
        height: 100%;
        .title {
          padding-bottom: 15px;
          h2 {
            font-size: 46px;
            line-height: 54px;
          }
          &::after {
            width: 100%;
            background-size: 100%;
            bottom: 0px;
          }
        }
        .sub_title {
          h3 {
            font-size: 45px;
            line-height: 70px;
            span {
              font-size: 36px;
            }
          }
        }
        .desc {
          span {
            font-size: 18px;
            line-height: 24px;
          }
        }
        .hero_action {
          a {
            flex-wrap: wrap;
            font-size: 16px;
            padding: 6px 18px;
          }
        }
      }
      .hero_graphic {
        height: 100%;
        margin-top: 50px;
        .graphic_img_wrapper {
          margin-top: 30px;
          &::after {
            height: 75%;
            width: 90%;
            position: absolute;
            bottom: 45px;
            right: -5px;
          }
        }
      }
    }
  }
  .why_choose {
    padding: 0px 0 90px 0;
    .wc_title {
      h2 {
        font-size: 46px;
        margin-bottom: 0;
      }
      p {
        font-size: 18px;
      }
    }
    .wc_box {
      margin-top: 25px;
      padding: 30px 20px;
      .count {
        font-size: 35px;
        line-height: 24px;
        span {
          font-size: 34px;
        }
      }
    }
  }
  .what_cw_do {
    .heading {
      h2 {
        font-size: 46px;
        margin-bottom: 0;
      }
      p {
        font-size: 18px;
      }
      .big {
        flex-wrap: wrap;
        padding-top: 30px;
        span {
          font-size: 30px !important;
          line-height: 30px !important;
        }
      }
    }
    .service_box {
      margin-top: 30px;
      padding: 30px;
      .title {
        &::after {
          bottom: -10px;
        }
        .text {
          font-size: 22px;
          line-height: 26px;
        }
        .icon {
          height: 35px;
          width: 35px;
          img {
            width: 100%;
          }
        }
      }
      .services {
        margin-top: 20px;
        flex-direction: column;
        .service_wrapper {
          margin-top: 20px;
          padding: 0 0;
          .text {
            font-size: 18px;
            line-height: 24px;
            width: 100%;
          }
          .icon {
            width: 50px;
            height: 50px;
            margin-bottom: 5px;
          }
        }
      }
    }
    .view_all {
      justify-content: center;
      padding: 30px 0 0px 0;
      button {
        font-size: 18px;
        line-height: 24px;
        img {
          width: 34px;
        }
      }
    }
  }
  .case_studies {
    padding: 50px 0;
    .heading {
      margin-bottom: 50px;
      .title {
        font-size: 46px;
        margin-bottom: 0;
      }
      .des {
        font-size: 18px;
        line-height: 24px;
        width: 100%;
      }
    }
    .owl-item {
      &.center {
        transform: scale(0.9);
      }
    }
    .case_box {
      padding: 20px;
      margin: 0 auto;
      border-radius: 15px;
      &::after {
        width: 25%;
      }
      .content {
        .image {
          max-width: 180px;
        }
        .links {
          padding-left: 20px;
          .text {
            font-size: 26px;
            line-height: 38px;
          }
          .store {
            button {
              background-color: transparent;
              border: 0px;
              margin-right: 8px;
            }
          }
        }
      }
      .action {
        margin-top: 10px;
        justify-content: flex-end;
        padding: 10px 0 0px 0;
        a {
          font-size: 18px;
          line-height: 24px;
          img {
            margin-left: 20px;
            width: 30px;
          }
        }
      }
    }
    .view_all {
      margin-top: 10px;
      justify-content: center;
      padding: 10px 0 0px 0;
      a {
        font-size: 18px;
        line-height: 24px;
        img {
          margin-left: 20px;
          width: 30px;
        }
      }
    }
  }
  .client_speak {
    padding-bottom: 100px;
    .heading {
      margin-bottom: 80px;
      h2 {
        font-size: 46px;
        margin-bottom: 10px;
        line-height: 46px;
        margin-top: 30px;
      }
      .desc {
        p {
          font-size: 18px;
          line-height: 24px;
          width: 100%;
        }
      }
    }
    .client_wrapper {
      // margin-left: 15px;
      .owl-nav {
        top: -65px;
        right: unset;
        left: -15px;
      }
      .slide_action {
        button {
          display: none;
        }
      }
      .client_box {
        width: calc(100% - 15px);
        border-radius: 10px;
        // padding: 15px;
        .content {
          margin-top: 25px;
          .button {
            bottom: 72px;
            width: 40px;
          }
          .avtar {
            width: 50px;
            height: 50px;
          }
          .details {
            .name {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  .clients {
    padding-bottom: 50px;
    .heading {
      margin-bottom: 50px;
      .title {
        font-size: 46px;
        margin-bottom: 0;
      }
      .des {
        font-size: 18px;
        margin-top: 0;
      }
    }
    .client_wrapper {
      .client_logo {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(10),
        &:nth-child(11),
        &:nth-child(12) {
          margin-top: 0;
          margin-bottom: 0;
          margin-right: 0;
          margin-left: 0;
          height: 150px;
          width: 150px;
          margin: 10px;
        }
      }
    }
    .client_desc {
      width: 100%;
      p {
        margin-top: 10px;
      }
    }
    .view_all {
      margin-top: 10px;
      justify-content: center;
      padding: 10px 0 0px 0;
      button {
        font-size: 18px;
        line-height: 24px;
        img {
          margin-left: 20px;
          width: 35px;
        }
      }
    }
  }
  .home_contect {
    padding: 60px 0;
  }
} /* Tablet Devices */
@media screen and (max-width: 991px) {
  .what_we_believe {
    .heading {
      h2 {
        font-weight: 700;
        font-size: 36px;
        line-height: 67px;
        color: #1886cd;
      }
      p {
        font-weight: 500;
        font-size: 18px;
        line-height: 35px;
        color: #0d0f27;
        span {
          color: #1886cd;
          position: relative;
          text-transform: capitalize;
          &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: #1886cd;
            left: 0;
            bottom: -10px;
          }
        }
      }
    }
  }
  .industries_worked{
    padding: 100px 0 0px;
    .ind_title{
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 50px;
    }
    .ind_type{
      font-size: 30px;
      line-height: 40px;
    }
    .ind_box{
      padding: 30px 20px;
      &.big{
        padding: 50px 40px;
        height: 400px;
        .name{
          font-size: 28px;
        }
      }
      .name{
        font-size: 24px;
      }
    }
  }

} /* Tablet Devices */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .hero {
    .hero_wrapper {
      .content {
        height: 100%;
      }
      .hero_graphic {
        height: 100%;
        padding: 100px 0 0;
      }
    }
  }
  .what_cw_do {
    .heading {
      .big {
        span {
          font-size: 54px;
        }
      }
    }
  }
  .why_choose {
    padding: 60px 0;
    .wc_title {
      p {
        margin-bottom: 50px;
      }
    }
  }
  .what_cw_do {
    .service_box {
      margin-top: 35px;
    }
    .heading {
      .big {
        padding: 20px;
      }
    }
  }
  .case_studies {
    .heading {
      margin-bottom: 60px;
      .des {
        width: 100%;
      }
    }
    .case_box {
      width: 95%;
      margin: 0 auto;
      padding: 40px 40px 30px 40px;
      .action {
        margin-top: 15px;
      }
    }
    .view_all {
      padding: 50px 0 30px 0;
    }
  }
  .client_speak {
    padding-bottom: 100px;
    .client_wrapper {
      // margin-left: 40px;
      .client_box {
        .client_img {
          max-width: unset;
          max-height: unset;
          height: 260px;
        }
      }
      .owl-nav {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        top: -86px;
        right: unset;
        left: -10px;
      }
    }
  }
  .clients {
    padding-bottom: 50px;
    .heading {
      margin-bottom: 60px;
    }
    .client_wrapper {
      .client_logo {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(10),
        &:nth-child(11),
        &:nth-child(12) {
          margin-top: 0;
          margin-bottom: 0;
          margin-right: 0;
          margin-left: 0;
          height: 150px;
          width: 150px;
          margin: 10px;
        }
      }
    }
  }
  .clients {
    .client_desc {
      width: 100%;
    }
  }
  .home_contect {
    padding: 60px 0 60px 0;
    .contact_me {
      .contact_box {
        width: 100%;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .hero {
    .hero_wrapper {
      .content {
        height: 100%;
      }
      .hero_graphic {
        height: 100%;
        padding: 100px 0 0;
      }
    }
  }
  .what_cw_do {
    .heading {
      .big {
        span {
          font-size: 54px;
        }
      }
    }
  }
  .why_choose {
    padding: 60px 0;
    .wc_title {
      p {
        margin-bottom: 50px;
      }
    }
  }
  .what_cw_do {
    .service_box {
      margin-top: 35px;
    }
    .heading {
      .big {
        padding: 20px;
      }
    }
  }
  .case_studies {
    .heading {
      margin-bottom: 60px;
      .des {
        width: 100%;
      }
    }
    .case_box {
      width: 95%;
      margin: 0 auto;
      padding: 40px 40px 30px 40px;
      .action {
        margin-top: 15px;
      }
    }
    .view_all {
      padding: 50px 0 30px 0;
    }
  }
  .client_speak {
    padding-bottom: 100px;
    .client_wrapper {
      // margin-left: 40px;
      .client_box {
        .client_img {
          max-width: unset;
          max-height: unset;
          height: 260px;
        }
      }
      .owl-nav {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        top: -86px;
        right: unset;
        left: -10px;
      }
    }
  }
  .clients {
    padding-bottom: 50px;
    .heading {
      margin-bottom: 60px;
    }
    .client_wrapper {
      .client_logo {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(10),
        &:nth-child(11),
        &:nth-child(12) {
          margin-top: 0;
          margin-bottom: 0;
          margin-right: 0;
          margin-left: 0;
          height: 150px;
          width: 150px;
          margin: 10px;
        }
      }
    }
  }
  .clients {
    .client_desc {
      width: 100%;
    }
  }
  .home_contect {
    padding: 60px 0 60px 0;
    .contact_me {
      .contact_box {
        width: 100%;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .what_cw_do {
    .heading {
      .big {
        span {
          font-size: 54px !important;
        }
      }
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1023px) {
  .hero {
    padding: 110px 0 15px 0;
    .hero_wrapper {
      .content {
        .title {
          padding-bottom: 10px;
          &::after {
            height: 20px;
            bottom: 5px;
          }
          h2 {
            font-size: 62px;
            line-height: 80px;
          }
        }
        .sub_title {
          h3 {
            font-family: Helvetica;
            font-weight: 700;
            font-size: 50px;
            line-height: 76px;
            color: #0d0f27;
            span {
              font-size: 36.2211px;
            }
          }
        }
        .desc {
          span {
            font-size: 16px;
            line-height: 20px;
          }
        }
        .hero_action {
          a {
            font-size: 16px;
          }
        }
      }
      .hero_graphic {
        .graphic_img_wrapper {
          height: unset;
          &::after {
            height: 95%;
            bottom: -12px;
          }
        }
      }
    }
  }
  .why_choose {
    padding: 60px 0;
    .wc_title {
      p {
        margin-bottom: 50px;
      }
    }
  }
  .what_cw_do {
    .service_box {
      margin-top: 35px;
    }
    .heading {
      .big {
        padding: 20px;
      }
    }
  }
  .case_studies {
    .heading {
      margin-bottom: 60px;
      .des {
        width: 100%;
      }
    }
    .case_box {
      width: 95%;
      margin: 0 auto;
      padding: 40px 40px 30px 40px;
      .action {
        margin-top: 15px;
      }
    }
    .view_all {
      padding: 50px 0 30px 0;
    }
  }
  .client_speak {
    padding-bottom: 100px;
    .client_wrapper {
      // margin-left: 40px;
      .client_box {
        .client_img {
          max-width: unset;
          max-height: unset;
          height: 260px;
        }
      }
      .owl-nav {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        top: -86px;
        right: unset;
        left: -10px;
      }
    }
  }
  .clients {
    padding-bottom: 50px;
    .heading {
      margin-bottom: 60px;
    }
    .client_wrapper {
      .client_logo {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(10),
        &:nth-child(11),
        &:nth-child(12) {
          margin-top: 0;
          margin-bottom: 0;
          margin-right: 0;
          margin-left: 0;
          height: 150px;
          width: 150px;
          margin: 10px;
        }
      }
    }
  }
  .clients {
    .client_desc {
      width: 100%;
    }
  }
  .home_contect {
    padding: 60px 0 60px 0;
    .contact_me {
      .contact_box {
        width: 100%;
      }
    }
  }
}

/* Laptop Devices */
@media screen and (min-width: 1024px) and (max-width: 1399px) {
  .hero {
    padding: 110px 0 15px 0;
    .hero_wrapper {
      .content {
        .title {
          padding-bottom: 12px;
          &::after {
            height: 20px;
            bottom: 5px;
          }
          h2 {
            font-size: 62px;
            line-height: 80px;
          }
        }
        .sub_title {
          h3 {
            font-family: Helvetica;
            font-weight: 700;
            font-size: 50px;
            line-height: 76px;
            color: #0d0f27;
            span {
              font-size: 36.2211px;
            }
          }
        }
        .desc {
          span {
            font-size: 16px;
            line-height: 20px;
          }
        }
        .hero_action {
          a {
            font-size: 16px;
          }
        }
      }
      .hero_graphic {
        .graphic_img_wrapper {
          height: unset;
          &::after {
            height: 95%;
            bottom: -12px;
          }
        }
      }
    }
  }
  .what_cw_do {
    .heading {
      .big {
        span {
          font-size: 54px !important;
        }
      }
    }
  }
  .why_choose {
    padding: 80px 0;
    .wc_title {
      h2 {
        margin: 5px 0;
      }
      p {
        margin-bottom: 50px;
      }
    }
  }
  .what_cw_do {
    .heading {
      .big {
        padding-top: 10px;
      }
    }
    .service_box {
      margin-top: 30px;
      padding: 30px;
      .title {
        &::after {
          bottom: -10px;
        }
        .text {
          font-size: 22px;
          line-height: 26px;
        }
        .icon {
          height: 35px;
          width: 35px;
          img {
            width: 100%;
          }
        }
      }
      .services {
        margin-top: 20px;
        // flex-direction: column;
        .service_wrapper {
          margin-top: 20px;
          padding: 0 0;
          .text {
            font-size: 18px;
            line-height: 24px;
            width: 100%;
          }
          .icon {
            width: 50px;
            height: 50px;
            margin-bottom: 5px;
          }
        }
      }
    }
    .view_all {
      padding: 50px 0 0 0;
    }
  }
  .case_studies {
    .heading {
      .des {
        width: 100%;
      }
    }
    .case_box {
      padding: 40px 40px 30px 40px;
    }
  }
  .client_speak {
    .heading {
      margin-bottom: 50px;
    }
    .client_wrapper {
      // margin-left: 50px;
    }
    .client_box {
      // padding: 15px;
      .content {
        margin-top: 30px;
        margin-bottom: 0px;
        .button {
          bottom: 72px;
          width: 60px;
          right: 40px;
        }
        .avtar {
          height: 55px;
          width: 55px;
        }
      }
    }
    .owl-nav {
      top: -106px;
    }
  }
  .clients {
    padding-bottom: 50px;
    .heading {
      margin-bottom: 60px;
    }
    .client_wrapper {
      .client_logo {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(10),
        &:nth-child(11),
        &:nth-child(12) {
          margin-top: 0;
          margin-bottom: 0;
          margin-right: 0;
          margin-left: 0;
          height: 150px;
          width: 150px;
          margin: 10px;
        }
      }
    }
    .client_desc {
      width: 100%;
    }
  }
  .home_contect {
    padding: 50px 0;
  }
}

/* Desktop Devices */
@media screen and (min-width: 1366px) and (max-width: 1919px) {
}

/* TV Devices */
@media screen and (min-width: 1920px) {
}
@media screen and (max-width: 1399px) {
}

@media screen and (max-width: 575px) {
  .client_speak {
    padding-bottom: 100px;
    .heading {
      margin-bottom: 80px;
      h2 {
        font-size: 46px;
        margin-bottom: 10px;
        line-height: 46px;
        margin-top: 30px;
      }
      .desc {
        p {
          font-size: 18px;
          line-height: 24px;
          width: 100%;
        }
      }
    }
    .slick-prev{
      top: -8%;
      right: 90px;
    } .slick-next {
      top: -8%;
      right: 35px;
    }
    .client_wrapper {
      // margin-left: 15px;
      .owl-nav {
        top: -65px;
        right: unset;
        left: -15px;
      }
      .slide_action {
        button {
          display: none;
        }
      }
      .client_box {
        width: calc(100% - 15px);
        border-radius: 10px;
        max-width: 90vw;
        // padding: 15px;
        .content {
          margin-top: 25px;
          .button {
            bottom: 30px;
        width: 65px;
          }
          .avtar {
            width: 50px;
            height: 50px;
          }
          .details {
            .name {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  .industries_worked{
    padding: 20px 0 0px;
    .ind_title{
      font-size: 36px;
      line-height: 40px;
      margin-bottom: 30px;
    }
    .ind_type{
      font-size: 24px;
      line-height: 32px;
    }
    .ind_box{
      padding: 25px 20px;
      &.big{
        padding: 30px 25px;
        height: 400px;
      }
    }
  }
}