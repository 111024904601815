.not_fount{
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    flex-direction: column;
    h1{
        font-size: 100px;
        font-weight: 700;
    }
    h3{
        font-size: 70px;
    }
}

@media screen and (max-width: 676px){
    .not_fount{
        h3{
            font-size: 40px;
        }
    }
}